.mobileGameDownloadDialog {
	.p-dialog-header {
		border-radius: 16px 16px 0 0 !important;
		border-top: 1px solid #1f1f1f !important;
		border-left: 1px solid #1f1f1f !important;
		border-right: 1px solid #1f1f1f !important;
		background: #131313 !important;
		color: #ededed !important;
		padding: 12px !important;

		.p-dialog-title {
			font-family: Geist !important;
			font-size: 16px !important;
			font-style: normal !important;
			font-weight: 500 !important;
			line-height: normal !important;
		}
		.p-dialog-header-close-icon {
			color: #ededed;
		}
	}
	.p-dialog-content {
		padding: 16px !important;
		border-radius: 0px 0px 16px 16px;
		background: #000000 !important;
		border-bottom: 1px solid #1f1f1f !important;
		border-left: 1px solid #1f1f1f !important;
		border-right: 1px solid #1f1f1f !important;
		.lists {
			display: flex;
			flex-direction: column;
			gap: 10px;
			margin-bottom: 16px;
			width: 100%;
			.list {
				padding: 12px;
				width: 100%;
				border-radius: 12px;
				border: 1px solid #1f1f1f;
				background: #171717;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.left {
					display: flex;
					align-items: center;
					gap: 4px;
					.brand {
						color: #fff;
						font-family: Geist;
						font-size: 16px;
						font-style: normal;
						font-weight: 500;
						line-height: 24px; /* 150% */
						letter-spacing: -0.16px;
						.symbol {
							display: flex;
							gap: 2px;
							align-items: center;
							color: #9b9b9b;
							font-family: Geist;
							font-size: 12px;
							font-style: normal;
							font-weight: 500;
							line-height: normal;
							.icon {
								height: 10px;
								width: 10px;
							}
						}
					}
					.icon {
						height: 30px;
						width: 30px;
					}
				}
				.right {
					border-radius: 8px;
					background: #0d0d0d;
					color: #9b9b9b;
					font-size: 14px;
					font-weight: 500;
					padding: 8px;
					text-decoration: none;
					transition: 300ms;
					&:hover {
						background: #fff;
						color: #131313;
					}
				}
			}
		}
	}
}
