.main {
	:global(.p-dialog-content) {
		padding: 24px !important;
		color: #ffffff !important;
		border-radius: 16px !important;
		border: 1px solid #1d1d1d !important;
		background: #0d0d0d !important;
		font-family: Geist !important;
		.header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			:global(.pi) {
				cursor: pointer;
			}
		}
		.amountBox {
			margin-top: 20px;
			padding: 16px 0 0 0;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-direction: column;
			border-radius: 16px 16px 16px 16px;
			border-top: 1px solid #171717;
			border-right: 1px solid #171717;
			border-left: 1px solid #171717;
			background: #131313;
			.amountLabel {
				color: #5e5e5e;
				font-size: 16px;
				font-weight: 500;
			}
			.amount {
				margin-top: 30px;
				display: flex;
				align-items: baseline;
				.amountField {
					width: fit-content !important;
					background: transparent !important;
					border: 0;
					text-align: center;
					color: #fff;
					font-size: 26px;
					font-weight: 500;
					&:focus {
						box-shadow: none;
					}
				}
				sub {
					color: #5e5e5e;
					font-size: 16px;
					font-weight: 500;
				}
			}
			.usdPrice {
				color: #5e5e5e;
				font-size: 16px;
				font-weight: 500;
			}
			.percentages {
				margin-top: 30px;
				width: 60%;
				display: flex;
				justify-content: space-between;
				.percentage {
					cursor: pointer;
					border-radius: 17px;
					border: 0.5px solid #1b1b1b;
					background: linear-gradient(0deg, #080808 0%, #080808 100%), #080808;
					color: #9b9b9b;
					font-family: Geist;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					padding: 5px 10px;
					transition: 300ms;
					&:hover {
						opacity: 0.7;
					}
				}
			}
			.dropdown {
				cursor: pointer;
				position: relative;
				margin-top: 20px;
				padding: 12px 16px;
				border-radius: 0px 0px 16px 16px;
				border-top: 1px solid #171717;
				background: #131313;
				display: flex;
				align-items: center;
				width: 100%;
				gap: 10px;
				.logo {
					height: 36px;
				}
				.left {
					.currency {
						color: #fff;
						font-size: 14.75px;
						font-weight: 500;
						line-height: 24px;
						letter-spacing: -0.16px;
					}
					.balance {
						color: #9b9b9b;
						font-size: 14px;
						font-weight: 500;
						line-height: 16px;
						letter-spacing: -0.12px;
						span {
							color: #5e5e5e;
							font-size: 14px;
							font-weight: 500;
							line-height: 16px;
							letter-spacing: -0.12px;
						}
					}
				}
				.dropArrow {
					cursor: pointer;
					position: absolute;
					right: 20px;
					font-size: 22px;
				}
			}
			.dropDownOptions {
				transition: 300ms;
				width: 100%;
				.option {
					padding: 24px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					background: #000;
					cursor: pointer;
					.left {
						display: flex;
						align-items: center;
						gap: 5px;
						.logo {
							height: 36px;
						}
						color: #fff;

						font-size: 16px;
						font-weight: 500;
						span {
							color: #a7a7a8;
							font-size: 16px;
							font-weight: 500;
						}
					}
					.right {
					}
					&:hover {
						background: #0c0c0c;
					}
				}
			}
		}
		.fromAndToBox {
			margin-top: 16px;
			width: 100%;
			.label {
				color: #a7a7a8;
				font-size: 16px;
				font-weight: 500;
				margin-bottom: 4px;
			}
			.box {
				padding: 12px 16px;
				display: flex;
				justify-content: space-between;
				border-radius: 12px;
				border: 1px solid #171717;
				background: #131313;
				.left {
					display: flex;
					align-items: center;
					gap: 10px;
					img {
						height: 31px;
					}
					.info {
						color: #fff;
						font-size: 14px;
						font-weight: 500;
						line-height: 24px;
						letter-spacing: -0.16px;
						span {
							display: block;
							color: #9b9b9b;
							font-size: 12px;
							font-weight: 500;
							line-height: 16px;
							letter-spacing: -0.12px;
						}
					}
				}
				.right {
					align-items: center;
					border-radius: 8px;
					background: #0d0d0d;
					display: flex;
					padding: 8px;
					gap: 10px;
					align-self: stretch;
					img {
						height: 21px;
					}
				}
				&.noAccountInfo {
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					gap: 5px;

					.upper {
						display: flex;
						align-items: center;
						gap: 5px;
						img {
							height: 18px;
						}
					}
					span {
						color: #9b9b9b;
						font-size: 14px;
						font-weight: 500;
						line-height: 16px;
						letter-spacing: -0.12px;
						a {
							color: #4285f4;
							font-size: 14px;
							font-weight: 500;
							line-height: 16px;
							letter-spacing: -0.12px;
							text-decoration: none;
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.main {
		&:global(.p-dialog) {
			max-height: 100% !important;
			height: 100% !important;
		}
	}
}
