:root {
	font-size: 16px;
	--ButtonHoverOpacity: 0.7;
	--sds-size-stroke-border: 1px;
	--OffWhit: #ebebeb;
	--Cherry-Red: #e30239;
	--Orange: #ff6b00;
	--subtleText: #5f5f5f;
	--Primary-Green: #bf0;
	--Card: #0d0d0d;
	--Tag: #181818;
	--Text-Color: #a4a4a4;
	--White: #ffffff;
	--Border: #1a1a1a;
	--bodyBackground: #000000;
	--bodyTextColor: #ffffff;
	--borderColor: #2b313a;
	/*--borderColor: #202022;*/
	--logoutButtonTextColor: #ff2164;
	--whiteRGBText: 255;
	--whiteText: #ffffff;
	--blackColor: #000000;
	--blueColor: #3b5aff;

	--primaryButtonBgColor: #3b5aff;
	--primaryButtonTextColor: #ffffff;
	--primaryButtonBorderColor: #2b313a;
	--primaryButtonOutlinedBorderColor: #2b313a;
	--primarybuttonoutlinedtextcolor: #ffffff;

	--secondaryButtonBgColor: transparent;
	--secondaryButtonTextColor: #ffffff;
	--secondaryButtonBorderColor: #2b313a;

	--cardBgColor: #1d1c21;

	--errorBackground: #ffe7e6;
	--errorColor: #ff5757;
	--errorBorderColor: #ff5757;

	--linkColor: #0d6efd;
	--bs-link-color: #3b5aff;
	--dialogBG: rgb(36, 39, 49);
}
[data-theme="dark"] {
	--bodyBackground: #000000;
	--bodyTextColor: #ffffff;
	--borderColor: #2b313a;
	--logoutButtonTextColor: #ff2164;
	--whiteRGBText: 255;
	--whiteText: #ffffff;
	--blackColor: #000000;
	--blueColor: #3b5aff;
	--bs-link-color: #3b5aff;
}

@media only screen and (max-width: 900px) {
	:root {
		font-size: 14px;
	}
}
