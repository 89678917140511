:global(.p-confirm-dialog) {
	border-radius: 16px !important;
	border: 1px solid #1d1d1d !important;
	background: #0d0d0d !important;
	:global(.p-dialog-header) {
		border-radius: 16px 16px 0 0 !important;
		background: #0d0d0d !important;
		color: #fff !important;
		:global(.pi) {
			color: #fff !important;
		}
	}
	:global(.p-dialog-content) {
		border-radius: 0 0 16px 16px !important;
		background: #0d0d0d !important;
		color: #fff !important;
	}

	:global(.p-dialog-footer) {
		border-radius: 0 0 16px 16px !important;
		background: #0d0d0d !important;
		color: #fff !important;
	}
}
.main {
	margin-top: 20px;
	:global(.p-accordion-tab) {
		margin-bottom: 20px;
	}
	:global(.p-accordion-tab) {
		border: 1px solid #1d1d1d !important;
		background: #0d0d0d !important;
		border-radius: 16px !important;
		padding: 16px;
		margin-bottom: 20px !important;
		.headerClass {
			a {
				border: 0px solid #1d1d1d !important;
				background: #0d0d0d !important;
				height: 50px;
				padding: 0 !important;
				display: flex;
				align-items: center;
				color: #ffffff !important;
				:global(.p-accordion-toggle-icon) {
					position: absolute;
					right: 10px;
				}
			}
		}
		.contentClass {
			:global(.p-accordion-content) {
				margin-top: 20px;
				border-radius: 9px;
				border: 1px solid #1d1d1d;
				background: #000;
				display: flex;
				padding: 24px;
				flex-direction: column;
				align-items: flex-start;
				gap: 18px;
				align-self: stretch;
				color: #ffffff;
				.permissionsIncluded {
					.title {
						color: #a7a7a8;
						font-family: Geist;
						font-size: 16px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
						margin-bottom: 10px;
					}
					.permissions {
						display: flex;
						justify-content: center;
						align-items: center;
						gap: 4px;
						.permission {
							border-radius: 14px;
							border: 1px solid #282828;
							background: rgba(95, 95, 95, 0);
							color: #e4e4e4;
							font-family: Geist;
							font-size: 14px;
							font-style: normal;
							font-weight: 500;
							line-height: normal;
							padding: 8px;
						}
					}
				}
				.timer {
					display: flex;
					justify-content: space-between;
					align-items: center;
					width: 100%;
					border-top: 1px solid #1b1b1b;
					border-bottom: 1px solid #1b1b1b;
					padding: 18px 0;
					.box {
						display: flex;
						flex-direction: column;
						gap: 5px;
						.title {
							color: #a7a7a8;
							font-family: Geist;
							font-size: 16px;
							font-style: normal;
							font-weight: 500;
							line-height: normal;
						}
						.date {
							color: #fff;
							font-family: Geist;
							font-size: 16px;
							font-style: normal;
							font-weight: 500;
							line-height: normal;
						}
					}
				}
			}
		}
	}
	.keyBoxHeader {
		display: flex;
		align-items: center;
		gap: 10px;
		.keyBox {
			border-radius: 7px;
			background: #31c859;
			padding: 10px;
			img {
				height: 12px;
			}
			&.orange {
				background: #d28f0e;
			}
		}
		.title {
			color: #fff;
			font-family: Geist;
			font-size: 18px;
			font-weight: 500;
		}
		.dot {
			margin-top: 5px;
			display: flex;
			align-items: center;
			gap: 5px;
			color: #a7a7a8;
			font-family: Geist;
			font-size: 16px;
			font-weight: 500;
			img {
				height: 19px;
			}
		}
	}
	.needActivateBox {
		margin-top: 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 16px;
		padding: 16px;
		border: 1px solid #1d1d1d;
		background: #0d0d0d;
	}
}

@media only screen and (max-width: 768px) {
	.main {
		margin-top: 20px;
		:global(.p-accordion-tab) {
			margin-bottom: 20px;
		}
		:global(.p-accordion-tab) {
			border: 1px solid #1d1d1d !important;
			background: #0d0d0d !important;
			border-radius: 8px !important;
			padding: 8px 10px;
			margin-bottom: 15px !important;
			.contentClass {
				:global(.p-accordion-content) {
					.permissionsIncluded {
						.title {
							font-size: 14px;
							margin-bottom: 10px;
						}
						.permissions {
							gap: 4px;
							.permission {
								font-size: 12px;
								padding: 8px;
							}
						}
					}
					.timer {
						padding: 12px 0;
						.box {
							gap: 5px;
							.title {
								font-size: 14px;
							}
							.date {
								font-size: 12px;
							}
						}
					}
				}
			}
		}
		.keyBoxHeader {
			gap: 10px;
			.keyBox {
				padding: 10px;
			}
			.title {
				font-size: 16px;
			}
			.dot {
				margin-top: 5px;
				gap: 5px;
				font-size: 14px;
				img {
					height: 15px;
				}
			}
		}
		.needActivateBox {
			margin-top: 20px;
			padding: 16px;
		}
	}
}
