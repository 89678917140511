.rightPanel {
	width: 90%;
	// position: relative;
	height: 80vh;
	h1 {
		font-size: 32px;
		font-weight: 500;
		line-height: 39.68px;
		text-align: left;
		color: #ffffff;
		max-width: 350px;
		vertical-align: middle;
		margin: 0;
		img {
			height: 32px;
			margin-left: 2px;
			margin-bottom: -5px;
		}
	}
	h2 {
		font-size: 18px;
		font-weight: 500;
		line-height: 22.32px;
		text-align: left;
		color: #a7a7a8;
		margin-bottom: 50px;
		max-width: 90%;
	}
	.iFrameLoading {
		height: 500px !important;
	}
	.iframe {
		width: 100%;
		height: 100%;
		border: none;
		min-height: 500px;
		// position: absolute;
	}
}
@media only screen and (max-width: 768px) {
	.rightPanel {
		padding-top: calc(env(safe-area-inset-top) + 88px);
		padding-bottom: 100px;
		width: 100%;
		// min-height: 100vh;
		h1 {
			font-size: 20px;
			padding-left: 8px;
			line-height: 28px;
			img {
				height: 20px;
				margin-bottom: -2px;
			}
		}
		h2 {
			font-size: 14px;
			margin-bottom: 30px;
			line-height: 18px;
			max-width: 100%;
			padding-left: 8px;
		}
		.iFrameLoading {
			height: 500px !important;
			width: 90% !important;
			margin: auto !important;
		}
		.iframe {
			width: 100%;
			height: 70%;
			border: none;
			position: absolute;
		}
	}
}
