.start {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 100%;
	.logo {
		height: 48px;
		width: 67px;
		margin-bottom: 24px;
	}
	.subTitle {
		color: #909090;
		text-align: center;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
	}
	.form {
		width: 100%;
		margin-top: 20px;
		.inputField {
			margin-bottom: 100px;
			position: relative;
			.emailField {
				padding-left: 65px;
			}
			.tag {
				opacity: 1;
				position: absolute;
				left: 5px;
				top: -2px;
				padding: 5px 10px;
				background: #10233d;
				font-family: General Sans Variable;
				font-size: 10px;
				font-weight: 600;
				line-height: 12px;
				color: #52a8ff;
				border-radius: 8px;
			}
			:global(.p-inputtext) {
				width: 100%;
				background: #0a0a0a;
				border: 1px solid #181818;
				color: #ffffff;
			}
		}
	}

	.errorClass {
		text-align: left;
		display: block;
		margin-bottom: 15px;
		margin-top: 5px;
	}
	.forgotPassword {
		margin: 10px 0;
		color: #909090;
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		a {
			margin-left: 5px;
			text-decoration: none;
			color: #ffffff;
			&:hover {
				opacity: 0.7;
			}
		}
	}
	.continueButton {
		border-radius: 8px;
		background: #cefb50;
		font-size: 1rem;
		font-weight: 600;
		color: #000000 !important;
		margin: auto;
		margin-top: 100px;
		width: 100%;
		border: 0;
		&:disabled {
			background: #d1f177 !important;
			opacity: 1;
		}
		&:enabled {
			background: #cefb50 !important;
			border: 0px solid transparent;
		}
	}
}

@media only screen and (max-width: 768px) {
	.start {
		.form {
			width: 100%;
		}
		.continueButton {
			margin-top: 10px;
		}
	}
}
