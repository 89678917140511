.main {
	:global(.p-dialog-content) {
		padding: 24px !important;
		color: #ffffff !important;
		border-radius: 16px !important;
		border: 1px solid #1d1d1d !important;
		background: #0d0d0d !important;
		font-family: Geist !important;
		:global(.pi) {
			display: flex;
			justify-content: flex-end;
			cursor: pointer;
		}
		.header {
			text-align: center;
			color: #fff;
			font-size: 20px;
			font-weight: 500;
			:global(.pi) {
				font-size: 20px;
			}
		}
		.qrcode {
			display: flex;
			justify-content: center;
			margin: 20px 0;
		}
		.addressBox {
			border-radius: 8px;
			border: 1px solid #2a2a2a;
			background: #000;
			max-width: 90%;
			padding: 8px 12px;
			margin: auto;
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: #fff;
			font-size: 18px;
			font-weight: 500;
			:global(.pi) {
				border-radius: 5px;
				background: #313131;
				height: 26px;
				width: 26px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
			}
		}
		p {
			max-width: 70%;
			margin: auto;
			text-align: center;
			margin-top: 8px;
			color: #a7a7a8;
			text-align: center;
			font-size: 14px;
			font-weight: 500;
		}
	}
}

@media only screen and (max-width: 768px) {
	.main {
		&:global(.p-dialog) {
			max-height: 100% !important;
			height: 100% !important;
		}
	}
}
