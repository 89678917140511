.start {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	.button {
		background: #d1f177 !important;
		color: #000000 !important;
		padding: 10px 30px;
		font-weight: 500;
		border-radius: 8px;
		text-decoration: none;
		font-size: 1rem;
		text-transform: uppercase;
	}
}
