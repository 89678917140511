.main {
	.modalClass {
		border: 1px solid var(--Border) !important;
	}
	.maskClassName {
		opacity: var(--sds-size-stroke-border) !important;
		background: rgba(0, 0, 0, 0.85) !important;
	}
	.header {
		background: #0d0d0d !important;
		color: var(--White) !important;
		font-family: Geist !important;
		font-size: 20px !important;
		font-weight: 600 !important;
		padding: 10px !important;
		border-bottom: var(--sds-size-stroke-border) solid var(--Border) !important;
	}
	.contentClassName {
		background: #0d0d0d !important;
		padding: 12px !important;
		font-family: Geist !important;
		.list {
			border: var(--sds-size-stroke-border) solid var(--Border) !important;
			border-radius: 8px;
			.item {
				padding: 12px;
				background: #000;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.left {
					color: var(--subtleText);
					font-size: 14px;
					font-weight: 500;
					.balance {
						margin-bottom: 2px;
						color: var(--White);
						font-size: 16px;
						font-weight: 500;
						span {
							margin-left: 5px;
							color: #a7a7a8;
							text-align: center;
							font-size: 16px;
							font-weight: 500;
						}
					}
				}
				.right {
					color: var(--White);
					font-size: 16px;
					font-weight: 500;
					span {
						color: var(--Text-Color);
						font-size: 14px;
						font-weight: 500;
					}
					button {
						cursor: pointer;
						border-radius: 8px;
						border: 1px solid var(--Border);
						background: #121212;
						color: var(--OffWhit);
						padding: 8px 20px;
						font-weight: 600;
						font-size: 1rem;
						display: flex;
						gap: 5px;
						align-items: center;
						:global(.pi) {
							background: #bbff00;
							color: #000000;
							border-radius: 100%;
							padding: 3px;
							font-size: 12px;
						}
					}
					.meter_container {
						margin-top: 4px;
						width: 100%;
						background: var(--Tag);
						border-radius: 12.285px;
						overflow: hidden;
						height: 7.8px;
					}

					.meter_fill {
						height: 100%;
						background: linear-gradient(90deg, #52d49e 0%, #a9f037 31.2%, #fdf001 67%, #ff7c01 100%);
						transition: width 0.3s;
					}
				}
				&:nth-child(even) {
					background: #0c0c0c;
				}
				&:nth-child(1) {
					border-radius: 8px 8px 0 0;
				}
				&:nth-last-child(1) {
					border-radius: 0 0 8px 8px;
				}
			}
		}

		.emptyBox {
			border-radius: 12px;
			border-top: var(--sds-size-stroke-border) solid var(--Border, #1a1a1a);
			border-right: var(--sds-size-stroke-border) solid var(--Border, #1a1a1a);
			border-left: var(--sds-size-stroke-border) solid var(--Border, #1a1a1a);
			opacity: var(--sds-size-stroke-border);
			background: #131313;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			padding: 50px 0;
			img {
				height: 100px;
			}
			p {
				max-width: 300px;
				text-align: center;
				font-size: 16px;
				font-weight: 500;
				color: var(--Text-Color);
				span {
					color: var(--Orange);
				}
			}
		}
	}
}
