.main {
	z-index: 1;
	position: fixed;
	bottom: 0;
	width: 100%;
	padding: 15px 0;
	padding-bottom: max(env(safe-area-inset-bottom), 15px);
	border-top: 0.4px solid #ffffff40;
	background: #000000;
	.items {
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		.item {
			text-decoration: none;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: 5px;
			font-size: 14px;
			opacity: 0.7;
			color: #ffffff;
			&.active {
				opacity: 1;
				color: #cefb50;
			}
			&.disabled {
				pointer-events: none;
				opacity: 0.7;
			}
			img {
				height: 24px;
			}
		}
	}
}
