.webView {
	display: block;
}
.mobileView {
	display: none;
}
.main {
	margin-top: 100px;
	display: flex;
	gap: 40px;
	justify-content: center;
	.leftPanel {
		background: #0d0d0d;
		border-radius: 12px;
		height: 100%;
		width: 100%;
		max-width: 250px;
		border: 1px solid #1d1d1d;
		padding: 0 10px;
		position: sticky;
		top: 100px;
		.heading {
			border-bottom: 1px solid #242424;
			height: 50px;
			padding: 0 20px;
			display: flex;
			align-items: center;
			font-size: 16px;
			font-weight: 500;
			line-height: 19.84px;
			text-align: left;
		}
		.items {
			margin: 20px 0;
			.item {
				display: flex;
				align-items: center;
				gap: 5px;
				padding: 0 20px;
				height: 40px;
				color: #9a9a9a;
				cursor: pointer;
				text-decoration: none;
				border: 1px solid transparent;
				transition: 300ms;
				opacity: 0.7;
				color: #ffffff;
				margin: 2px 0;

				img {
					height: 15px;
					width: 15px;
				}
				&.disabled {
					opacity: 0.7;
					pointer-events: none;
				}
				&.active {
					background: #171717;
					color: #cefb50;
					border: 1px solid #1d1d1d;
					border-radius: 8px;
				}
				&:hover {
					background: #171717;
					color: #ffffff;
					border: 1px solid #1d1d1d;
					border-radius: 8px;
				}
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.webView {
		display: none;
	}
	.mobileView {
		display: block;
	}
}
