.showMoreOptionsDialog {
	border: 1px solid #202020 !important;
	z-index: 99999 !important;
	position: fixed !important;
	bottom: 0 !important;

	.headerClass {
		background-color: #0d0d0d !important;
		border-bottom: 1px solid #1f1f1f !important;
		color: #ffffff !important;
		padding: 1rem !important;
		:global(.p-dialog-title) {
			font-size: 1rem !important;
		}
	}
	.contentClass {
		padding: 10px 10px 30px 10px !important;
		background-color: #0d0d0d !important;
		color: #ffffff !important;
	}
	.tabs {
		margin-top: 20px;
		display: flex;
		align-items: center;
		gap: 20px;
		.tab {
			font-family: General Sans Variable !important;
			border-radius: 3px;
			&.activeTab {
				font-weight: 500;
				background: #1f1f1f;
				padding: 5px 10px;
			}
		}
	}
	.lists {
		margin-top: 20px;
		.divider {
			:global(.p-divider-content) {
				color: #909090;
				background-color: #000;
				font-weight: 600;
				text-transform: uppercase;
				font-size: 0.8rem;
			}
			&:before {
				border-top: 1px solid #1f1f1f;
			}
		}
		.list {
			cursor: pointer;
			background: #0a0a0a;
			margin-top: 10px;
			border: 1px solid #242424 !important;
			color: #ffffff;
			text-decoration: none;
			height: 52px;
			display: flex;
			align-items: center;
			gap: 10px;
			font-size: 1rem;
			padding-left: 1rem;
			border-radius: 8px;
			img {
				height: 1.3rem;
			}
		}
	}
}

.header {
	text-align: center;
	.logo {
		height: 48px;
		width: 67px;
	}
	h1 {
		margin-top: 15px;
		font-family: General Sans Variable !important;
		font-weight: 500;
		font-size: 1.5rem !important;
		span {
			font-size: 1.5rem !important;
			font-weight: 600;
		}
	}
}
.form {
	margin-top: 20px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 10px;
	.emailField {
		background: #0a0a0a;
		border: 1px solid #262626;
		height: 52px;
		line-height: 52px;
		color: #ffffff;
		border-radius: 8px;
		&.p_error {
			border: 1px solid #dd182f;
			color: #dd182f;
		}
	}
	.errorClass {
		text-align: left;
		display: block;
	}
	.continueButton {
		border-radius: 8px;
		background: #cefb50;
		font-size: 1rem;
		font-weight: 600;
		color: #000000 !important;
		border: 0px solid transparent !important;
		transition: 250ms;
		&:hover {
			opacity: 0.5;
		}
		&:disabled,
		&:enabled {
			background: #cefb50 !important;
		}
	}
}
.centerDivider {
	margin: 30px 0 !important;
	:global(.p-divider-content) {
		background-color: #000 !important;
		text-transform: uppercase;
		color: #909090 !important;
		font-family: Geist !important;
		font-size: 14px !important;
		font-weight: 600 !important;
	}
	&:before {
		border-top: 1px solid #1f1f1f !important;
	}
}
.socialButtons {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	width: 90%;
	gap: 8px;
	.socialButton {
		width: 100%;
		gap: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1rem;
		font-weight: 500;
		cursor: pointer;
		transition: 250ms;
		border-radius: 8px;
		border: 1px solid #242424;
		background: #0d0d0d;
		padding: 16px;
		&:hover {
			opacity: 0.5;
		}
		img {
			height: 16px;
		}
		&.moreButton {
			grid-column: 1 / -1;
			width: 70%;
			margin: auto;
			margin-top: 30px;
		}
	}
}

@media only screen and (max-width: 768px) {
	.header {
		text-align: center;
		.logo {
			height: 44px;
			width: 200px;
		}
		h1 {
			margin-top: 5px;
			font-family: General Sans Variable !important;
			font-weight: 500;
			font-size: 1.8rem !important;
			span {
				font-size: 1.5rem !important;
				font-weight: 600;
			}
		}
	}
	.centerDivider {
		:global(.p-divider-content) {
			font-size: 10px !important;
		}
	}
	.socialButtons {
		.socialButton {
			font-size: 14px;
			img {
				height: 16px;
			}
			&.moreButton {
				width: 60%;
				margin: auto;
				margin-top: 30px;
			}
		}
	}
}
