.main {
	.modalClass {
		border: 1px solid var(--Border) !important;
	}
	.maskClassName {
		background: rgba(0, 0, 0, 0.75) !important;
	}
	:global(.p-dialog-header-close-icon) {
		color: var(--White) !important;
	}
	.header {
		background: #000000 !important;
		color: var(--White) !important;
		font-family: Geist !important;
		font-size: 20px !important;
		font-weight: 600 !important;
		padding: 10px !important;
		border-bottom: var(--sds-size-stroke-border) solid var(--Border) !important;
	}
	.contentClassName {
		background: #080808 !important;
		padding: 0px !important;
		font-family: Geist !important;
		.body {
			display: grid;
			grid-template-columns: 250px 1fr;
			gap: 12px;
			min-height: 500px;

			.left {
				border-right: 1px solid var(--Border);
				color: var(--subtleText);
				font-family: Geist;
				font-size: 18px;
				font-weight: 500;
				line-height: 22.32px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				.lists {
					padding: 12px;
					.item {
						cursor: pointer;
						padding: 16px 14px;
						display: flex;
						align-items: center;
						gap: 4px;
						border-radius: 8px;
						img {
							height: 16px;
						}
						&.active {
							color: var(--White);
							background: #141414;
						}
					}
				}
				.rewardDistribution {
					display: flex;
					align-items: center;
					gap: 5px;
					padding: 16px 14px;
					border-top: 1px solid var(--Border);
					img {
						height: 18px;
					}
				}
			}
			.right {
				// overflow: scroll;
				// max-height: 500px;
				padding: 12px 12px 12px 0;
				.banner {
					height: 250px;
					width: 100%;
				}
				.title {
					margin-top: 20px;
					color: var(--White);
					font-size: 20px;
					font-weight: 500;
					line-height: 22.32px;
				}
				p {
					color: var(--Text-Color);
					font-size: 18px;
					font-weight: 400;
					line-height: 150%;
				}
				ol {
					li {
						color: var(--Text-Color);
						font-size: 18px;
						font-weight: 400;
						line-height: 150%;
						padding-bottom: 10px;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 900px) {
	.main {
		.header {
			font-size: 18px !important;
		}
		.contentClassName {
			.body {
				display: grid;
				grid-template-columns: repeat(1, 1fr);
				.left {
					font-size: 16px;
				}
				.right {
					overflow: scroll;
					max-height: max-content;
					padding: 12px 12px 12px 12px;
					.title {
						margin-top: 18px;
						font-size: 18px;
					}
					p {
						font-size: 16px;
					}
					ol {
						li {
							font-size: 16px;
						}
					}
				}
			}
		}
	}
}
