.main {
	:global(.p-dialog-content) {
		padding: 48px !important;
		color: #ffffff !important;
		border-radius: 16px !important;
		border: 1px solid #1d1d1d !important;
		background: #0d0d0d !important;
		text-align: center !important;
		.header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: #fff;
			font-family: Geist;
			font-size: 20px;
			font-weight: 500;
			line-height: 32px;
			margin-bottom: 5px;
			:global(.pi) {
				display: block;
				position: absolute;
				right: 20px;
				font-size: 20px;
			}
		}
		h1 {
			max-width: 90%;
			margin: auto;
			color: #fff;
			text-align: center;
			font-family: Geist;
			font-size: 24px;
			font-weight: 500;
			line-height: 32px;
		}
		p {
			color: #a7a7a8;
			text-align: center;
			font-family: Geist;
			font-size: 16px;
			font-weight: 400;
		}
		.success_tick {
			height: 55px;
		}
		.activateButton {
			margin-top: 20px;
			width: 100%;
			background: #0070f3 !important;
		}
	}
}

@media only screen and (max-width: 768px) {
	.main {
		:global(.p-dialog-content) {
			padding: 24px !important;
		}
	}
}
