.start {
	.steps {
		color: #909090;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
	}
	h2 {
		font-size: 24px;
		font-weight: 500;
		line-height: 32px;
		margin: 5px 0;
	}
	p {
		color: #909090;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
	}
	.inputField {
		width: 100%;
		margin-bottom: 10px;
		position: relative;
		.emailField {
			padding-left: 63px;
		}
		.tag {
			padding: 5px 10px;
			background: #10233d;
			font-size: 10px;
			font-weight: 600;
			line-height: 12px;
			color: #52a8ff;
			border-radius: 8px;
			position: absolute;
			top: 12px;
			left: 10px;
		}
	}

	:global(.p-inputtext) {
		width: 100%;
		background: #0a0a0a;
		border: 1px solid #181818;
		color: #fff;
		&:disabled {
			opacity: 1;
			color: #fff;
		}
	}
	.errorClass {
		font-size: 12px;
	}
	.suggestion {
		border: 1px solid #1f1f1f;
		padding: 10px;
		color: #909090;
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
	}
	.buttons {
		position: absolute;
		bottom: 30px;
		width: 95%;
		margin-top: 200px;
		.resendButtonSection {
			width: 97%;
			gap: 24px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 32px;
			color: #909090;
			font-size: 14px;
			font-weight: 500;
			line-height: 20px;
			.resendButton {
				width: 112px;
			}
		}
		.continueButton {
			width: 100%;
			border-radius: 8px;
			background: #cefb50;
			font-size: 1rem;
			font-weight: 600;
			color: #000000 !important;
			margin: auto;
			border: 0;
			&:disabled {
				background: #d1f177 !important;
				opacity: 1;
			}
			&:enabled {
				background: #cefb50 !important;
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.start {
		.inputField {
			.tag {
				top: 10px;
			}
		}
	}
}
