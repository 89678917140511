.rightPanel {
	width: 50%;
	.banner {
		border-radius: 12px 12px 0 0;
		padding: 16px 27px;
		position: relative;
		height: 230px;
		background-image: url("/assets/pointfarm/banner.png");
		background-size: cover;
		.tags {
			position: absolute;
			display: flex;
			gap: 5px;
			.tag {
				font-family: "Geist Mono";
				padding: 5px 10px;
				border-radius: 6px;
				border: 1px solid var(--Border);
				opacity: var(--sds-size-stroke-border);
				background: var(--Card);
				color: var(--Text-Color);
				font-size: 14px;
				font-weight: 400;
			}
		}
		.infoArea {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			height: 100%;
			h1 {
				color: var(--White, #fff);
				font-size: 24px;
				font-weight: 600;
				margin: 0;
			}
			p {
				color: var(--Text-Color);
				font-family: Geist;
				font-size: 16px;
				font-weight: 400;
				margin: 4px 0 0 0;
				max-width: 350px;
			}
			.tips {
				cursor: pointer;
				display: flex;
				align-items: center;
				gap: 10px;
				border-radius: 50px;
				border: 1px solid var(--Border);
				opacity: var(--sds-size-stroke-border);
				background: var(--Card);
				color: var(--Primary-Green);
				font-size: 14px;
				font-weight: 400;
				padding: 5px 15px;
				text-decoration: none;
				transition: 300ms;
				img {
					height: 14px;
				}
				&:hover {
					opacity: 0.8;
				}
			}
		}
	}
	.walletArea {
		width: 100%;
		padding: 8px 16px;
		border-radius: 0px 0px 12px 12px;
		border-right: var(--sds-size-stroke-border) solid var(--Border);
		border-bottom: var(--sds-size-stroke-border) solid var(--Border);
		border-left: var(--sds-size-stroke-border) solid var(--Border);
		background: var(--Card);
		display: flex;
		justify-content: space-between;
		align-items: center;
		.title {
			display: flex;
			gap: 4px;
			align-items: center;
			color: var(--Text-Color);
			font-size: 14px;
			font-weight: 500;
			.greenDot {
				width: 11.221px;
				height: 11.221px;
				background: #31c859;
				border-radius: 50%;
			}
			.redDot {
				width: 11.221px;
				height: 11.221px;
				background: #dd182f;
				border-radius: 50%;
			}
		}
		.address {
			display: flex;
			align-items: center;
			gap: 4px;
			border-radius: 4px;
			background: var(--Tag);
			padding: 4px 10px;
			color: #a7a7a8;
			font-family: "Geist Mono";
			font-size: 14px;
			font-weight: 400;
			transition: 300ms;
			img {
				height: 18px;
			}
			&.connect {
				&:hover {
					opacity: var(--ButtonHoverOpacity);
				}
			}
		}
	}

	.pointsBox {
		margin-top: 20px;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 10px;
		.box {
			border-radius: 12px;
			padding: 24px;
			border: 1px solid var(--Border, #1a1a1a);
			opacity: var(--sds-size-stroke-border);
			background: var(--Card);
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			&.pointsXBox {
				grid-column: 1 / 3;
				grid-row: 1 / 3;
				.connectButton {
					margin-top: 20px;
					border: none !important;
					background: var(--Primary-Green) !important;
					color: var(--Black, #000);
					font-size: 16px;
					font-weight: 600;
					text-transform: uppercase;
					transition: 300ms;
					min-width: 230px;
					&:hover {
						opacity: var(--ButtonHoverOpacity);
					}
				}
			}
			.tag {
				padding: 5px 15px;
				width: fit-content;
				text-align: center;
				color: var(--Orange);
				text-align: center;
				font-size: 14px;
				font-weight: 500;
				border-radius: 35px;
				border: 1px solid var(--Border);
				opacity: var(--sds-size-stroke-border);
				background: #090909;
			}
			.points {
				margin: 10px 0;
				color: var(--White);
				font-size: 32px;
				font-weight: 500;
				font-family: "Geist Mono";
			}
			.title {
				display: flex;
				gap: 5px;
				align-items: center;
				color: var(--Text-Color);
				text-align: center;
				font-size: 20px;
				font-weight: 500;
			}
			.subtitle {
				display: flex;
				gap: 5px;
				align-items: center;
				color: var(--Text-Color);
				text-align: center;
				font-size: 16px;
				font-weight: 500;
			}
			.links {
				color: var(--subtleText);
				font-family: Geist;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				span {
					opacity: var(--sds-size-stroke-border);
					color: var(--Orange);
					font-family: Geist;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
				}
			}
		}
	}

	.cachedAndWithdrawBox {
		margin-top: 20px;
		gap: 9px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		.box {
			border-radius: 12px;
			border: 1px solid var(--Border);
			opacity: var(--sds-size-stroke-border);
			background: var(--Card, #0d0d0d);
			padding: 16px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.points {
				color: var(--White);
				font-family: "Geist Mono";
				font-size: 24px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}
			.title {
				color: var(--Text-Color);
				font-size: 16px;
				font-weight: 500;
				margin: 8px 0;
			}
			p {
				color: var(--subtleText);
				font-size: 14px;
				font-weight: 500;
				span {
					color: var(--Orange);
					font-size: 14px;
					font-weight: 500;
				}
				a {
					color: var(--OffWhit);
					font-size: 14px;
					font-weight: 500;
					text-decoration-line: underline;
				}
			}
			.decachedButton {
				cursor: pointer;
				padding: 8px 16px;
				border-radius: 8px;
				border: 1px solid #1e1e1e;
				background: #121212;
				color: var(--Cherry-Red);
				font-size: 16px;
				font-weight: 600;
				transition: 300ms;
				&:hover {
					opacity: var(--ButtonHoverOpacity);
				}
				&:disabled {
					opacity: 0.7;
				}
			}
			.withdrawButton {
				cursor: pointer;
				padding: 8px 16px;
				border-radius: 8px;
				border: 1px solid #1e1e1e;
				background: #121212;
				color: var(--OffWhit);
				font-size: 16px;
				font-weight: 600;
				&:disabled {
					opacity: 0.7;
				}
			}
		}
	}

	.leaderboardTabBar {
		display: flex;
		gap: 10px;
		margin-top: 20px;
		border-radius: 16px;
		border: 1px solid #1c1c1c;
		background: var(--Card, #0d0d0d);
		width: fit-content;
		padding: 5px;
		.tab {
			cursor: pointer;
			padding: 8px 16px;
			border-radius: 12px;
			color: var(--Text-Color, #a4a4a4);
			font-family: Geist;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}
		.active {
			background: #1c1c1c;
			color: var(--White, #fff);
			font-family: Geist;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}
		.disabled {
			opacity: 0.3;
			pointer-events: none;
		}
	}
}

@media only screen and (max-width: 900px) {
	.rightPanel {
		margin-top: 80px;
		padding: 10px;
		width: 100%;
		padding-bottom: 100px;
		.banner {
			padding: 5px;
			height: 150px;
			.tags {
				.tag {
					padding: 5px 10px;
					font-size: 12px;
				}
			}
			.infoArea {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;
				flex-direction: column-reverse;
				height: 100%;
				h1 {
					font-size: 20px;
				}
				p {
					font-size: 14px;
				}
				.tips {
					display: flex;
					align-items: center;
					gap: 10px;
					border-radius: 50px;
					border: 1px solid var(--Border);
					opacity: var(--sds-size-stroke-border);
					background: var(--Card);
					color: var(--Primary-Green);
					font-size: 14px;
					font-weight: 400;
					padding: 5px 15px;
					text-decoration: none;
					img {
						height: 14px;
					}
				}
			}
		}

		.pointsBox {
			grid-template-columns: repeat(1, 1fr);
			.box {
				padding: 16px;
				&.pointsXBox {
					grid-column: 1 / 1;
					grid-row: 1 / 1;
				}
			}
		}

		.cachedAndWithdrawBox {
			grid-template-columns: repeat(1, 1fr);
			.box {
				border-radius: 12px;
				border: 1px solid var(--Border);
				opacity: var(--sds-size-stroke-border);
				background: var(--Card, #0d0d0d);
				padding: 16px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.points {
					color: var(--White);
					font-family: "Geist Mono";
					font-size: 24px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
				}
				.title {
					color: var(--Text-Color);
					font-size: 16px;
					font-weight: 500;
					margin: 8px 0;
				}
				p {
					color: var(--subtleText);
					font-size: 14px;
					font-weight: 500;
					span {
						color: var(--Orange);
						font-size: 14px;
						font-weight: 500;
					}
					a {
						color: var(--OffWhit);
						font-size: 14px;
						font-weight: 500;
						text-decoration-line: underline;
					}
				}
				.decachedButton {
					cursor: pointer;
					padding: 8px 16px;
					border-radius: 8px;
					border: 1px solid #1e1e1e;
					background: #121212;
					color: var(--Cherry-Red);
					font-size: 16px;
					font-weight: 600;
					&:disabled {
						opacity: 0.7;
					}
				}
				.withdrawButton {
					cursor: pointer;
					padding: 8px 16px;
					border-radius: 8px;
					border: 1px solid #1e1e1e;
					background: #121212;
					color: var(--OffWhit);
					font-size: 16px;
					font-weight: 600;
					&:disabled {
						opacity: 0.7;
					}
				}
			}
		}
	}
}
