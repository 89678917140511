.start {
	padding: 0 10px;
	p {
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		color: #909090;
		margin: 5px 0;
		text-align: left;
		font-family: Geist;
		&.info {
			font-size: 10px;
			font-weight: 600;
			line-height: 12px;
		}
	}
	.inputField {
		margin-top: 10px;
		margin-bottom: 10px;
		width: 100%;
		.emailField {
			padding-left: 63px;
		}
		.tag {
			opacity: 1;
			position: absolute;
			left: 5px;
			top: 10px;
			padding: 5px 10px;
			background: #10233d;
			font-size: 10px;
			font-weight: 600;
			line-height: 12px;
			color: #52a8ff;
			border-radius: 8px;
		}
		:global(.p-inputtext) {
			width: 100%;
			background: #0a0a0a;
			border: 1px solid #181818;
			color: #ffffff;
			&:disabled {
				opacity: 1;
				color: #fff;
			}
		}
	}
	.forgotPassword {
		margin: 10px 0;
	}
	.continueButton {
		font-family: "Geist" !important;
		border-radius: 8px;
		background: #cefb50;
		font-size: 1rem;
		font-weight: 600;
		color: #000000 !important;
		margin: auto;
		margin-top: 30px;
		width: 100%;
		border: 0px;
		&:disabled {
			background: #d1f177 !important;
			opacity: 1;
		}
		&:enabled {
			background: #cefb50 !important;
		}
	}
	.otherOptions {
		margin-top: 10px;
		color: #909090;
		font-size: 0.8rem;
		span {
			color: #ffffff;
		}
	}
	.info {
		text-align: center;
		margin-top: 20px;
		color: #5b5b5b;
		font-size: 10px;
		font-weight: 600;
		line-height: 12px;
	}
}

@media only screen and (max-width: 768px) {
}
