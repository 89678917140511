.rightPanel {
	width: 50%;
	h1 {
		font-size: 32px;
		font-weight: 500;
		line-height: 39.68px;
		text-align: left;
		color: #ffffff;
		max-width: 300px;
		vertical-align: middle;
		margin: 0;
		img {
			height: 32px;
			margin-left: 2px;
			margin-bottom: -5px;
		}
	}
	h2 {
		font-family: Geist;
		font-size: 18px;
		font-weight: 500;
		line-height: 22.32px;
		text-align: left;
		color: #a7a7a8;
		margin-bottom: 50px;
	}
	.box {
		background: #0d0d0d;
		border: 1px solid #1d1d1d;
		border-radius: 22px;
		margin-bottom: 20px;
		.heading {
			font-size: 20px;
			font-weight: 500;
			line-height: 24.8px;
			text-align: left;
			height: 72px;
			display: flex;
			align-items: center;
			border-bottom: 1px solid #1d1d1d;
			padding-left: 20px;
		}
		.item {
			border-bottom: 1px solid #1d1d1d;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 20px 20px;
			gap: 20px;
			&:nth-last-child(1) {
				border-bottom: 0px solid #1d1d1d;
			}
			.left {
				display: flex;
				gap: 10px;
				align-items: center;
				.icon {
					height: 30px;
				}
				.title {
					color: #a7a7a8;
					font-size: 18px;
					font-weight: 500;
					width: max-content;
				}
				.passwordInfo {
					font-size: 14px;
					font-weight: 500;
					display: flex;
					align-items: center;
					gap: 5px;
					span {
						border-radius: 50%;
					}
				}
				p {
					color: #a7a7a8;
					font-size: 16px;
					font-weight: 500;
					margin: 0;
					margin-top: 4px;
				}
			}
			.dp {
				position: relative;
				img {
					border-radius: 50%;
					height: 72px;
					width: 72px;
				}
				:global(.pi-user),
				:global(.pi-user-edit) {
					cursor: pointer;
					background: #222;
					border-radius: 50%;
					padding: 24px;
					font-size: 25px;
				}
				:global(.pi-file-edit) {
					cursor: pointer;
					background: #222;
					padding: 10px;
					border-radius: 50%;
					position: absolute;
					bottom: -10px;
					right: 5px;
				}
			}
			.passwordWrapper {
				width: 100%;
				display: flex;
				.passwordField {
					width: 50%;
					display: flex;
					flex-direction: column;
					label {
						color: #a7a7a8;
						font-size: 16px;
						font-weight: 500;
						margin-bottom: 8px;
					}
				}
			}
			.inputField {
				width: 90%;
				border-radius: 9.789px;
				border: 1px solid #1d1d1d;
				background: #000;
				color: #ffffff;
				&.p_error_input {
					color: #e24c4c;
					border: 1px solid #e24c4c;
				}
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.rightPanel {
		padding-top: calc(env(safe-area-inset-top) + 88px);
		padding-bottom: 100px;
		width: 100%;
		min-height: 100vh;
		h1 {
			font-size: 20px;
			padding-left: 8px;
			line-height: 28px;
			img {
				height: 20px;
				margin-bottom: -2px;
			}
		}
		h2 {
			font-size: 14px;
			margin-bottom: 30px;
			line-height: 18px;
			max-width: 100%;
			padding-left: 8px;
		}
		.box {
			border-radius: 8px;
			.heading {
				font-size: 16px;
				height: 50px;
				padding-left: 10px;
			}
			.item {
				padding: 15px 10px;
				gap: 10px;

				&.passwords {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					.left {
						.title {
							font-size: 14px;
						}
					}
				}

				.passwordWrapper {
					flex-direction: column;
					.passwordField {
						width: 100%;
						display: flex;
						flex-direction: column;
						label {
							font-size: 14px;
						}
					}
				}
				.inputField {
					width: 100%;
				}
				&.buttons {
					display: flex;
					flex-direction: column-reverse;
					button {
						width: 100%;
					}
				}
				&.system {
					flex-direction: column;
					align-items: flex-start;
					button {
						width: 100%;
					}
				}
			}
		}
	}
}
