@import "primereact/resources/themes/tailwind-light/theme.css";
@import "primereact/resources/primereact.min.css";
@import "primeicons/primeicons.css";
@import "primeflex/primeflex.scss";
@import "theme.scss";

html {
	scroll-behavior: smooth !important;
}

@font-face {
	font-family: "Geist";
	src: url("/assets/fonts/GeistVF.ttf") format("truetype");
	font-weight: 100 500 900;
}
@font-face {
	font-family: "Geist Mono";
	src: url("/assets/fonts/GeneralSans/GeistMonoVF.ttf") format("truetype");
	font-weight: 100 500 900;
}
@font-face {
	font-family: "General Sans Variable";
	src: url("/assets/fonts/GeneralSans/GeneralSans-Variable.ttf") format("truetype");
	font-weight: 100 500 900;
}

body {
	overflow: scroll !important;
	font-family: "Geist";
	margin: 0;
	background-color: #000000 !important;
	color: var(--bodyTextColor) !important;
	font-size: 1rem;
	scroll-behavior: smooth;
}
.container {
	margin: auto;
	width: clamp(300px, 100%, 1440px);
}
.p-button {
	background: #3b5aff !important;
	&.p-button-text {
		background: transparent !important;
		&.tooltip-button {
			color: var(--Text-Color);
			&:hover {
				color: var(--Text-Color);
			}
		}
	}
}
.p-button-secondary {
	background: #292d32 !important;
	border: 1px solid #1d1d1d !important;
	font-size: 16px !important;
	font-weight: 600 !important;
	line-height: 19.84px !important;
	color: #ffffff !important;
	&.p-button-outlined {
		background: transparent !important;
	}
}
.p-button-danger {
	background: #e30239 !important;
	border: 1px solid #e30239 !important;
}

.mobileHeader {
	height: 64px;
	font-size: 20px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 0px;
	.leftSide {
		display: flex;
		align-items: center;
	}
	.backIcon {
		margin-right: 20px;
		font-size: 20px;
		cursor: pointer;
	}
	.rightIcon {
		font-size: 24px;
	}
	.rightSide {
		img {
			height: 25px;
		}
	}
}

.p-button-danger {
	background: #e30239 !important;
	transition: 250ms !important;
	&:hover {
		opacity: 0.5 !important;
	}
}
.p-button-outlined {
	transition: 250ms !important;
	&:hover {
		opacity: 0.5 !important;
	}
}

.messageBox {
	position: fixed;
	right: 0;
	top: 0px;
	z-index: 111;
}
.p-button-label {
	font-size: 1rem !important;
	font-family: "Geist" !important;
	font-weight: 600 !important;
}
.goBackToAllOptions {
	margin-top: 10px;
	display: block;
	text-align: center;
	color: #909090;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	a {
		cursor: pointer;
		color: #fff;
		font-family: "General Sans Variable";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px;
		text-decoration-line: underline;
	}
}

@media only screen and (max-width: 768px) {
	.container {
		margin: auto;
		width: clamp(90vw, 100%, 95vw);
	}
	.mobileHeader {
		padding: 0 10px;
		.backIcon {
			margin-right: 20px;
			font-size: 20px;
			cursor: pointer;
		}
		.rightIcon {
			font-size: 24px;
		}
		.rightSide {
			img {
				height: 25px;
			}
		}
	}
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
	box-shadow: none !important;
}

.p-skeleton {
	background: #1d1c21 !important;

	&:after {
		background: linear-gradient(90deg, rgba(34, 33, 39, 0), rgba(37, 36, 44, 0.4), rgba(255, 255, 255, 0));
	}
}

meter::-webkit-meter-bar {
	background: rgba(196, 255, 31, 0.06);
	border: none;
}

meter::-webkit-meter-optimum-value {
	background: #c4ff1f;
	border-radius: 10px;
}
meter::-webkit-meter-suboptimum-value {
	background: #c4ff1f;
}
meter::-webkit-meter-even-less-good-value {
	background: #c4ff1f;
}
meter::-webkit-meter-value {
	background: #c4ff1f;
}

.wallet-adapter-button-trigger {
	border-radius: 9.789px !important;
	border: 1px solid #1d1d1d !important;
	background: #121212 !important;
	width: 150px;
	justify-content: center;
}

.tooltip-help {
	max-width: 300px;
	.p-tooltip-text {
		color: var(--White) !important;
		font-size: 14px;
		background: #171717 !important;
		border: 1px solid #1a1a1a !important;
	}
}

w3m-modal {
	z-index: 99999;
}

.joinWaitListDialog {
	border: 1px solid #1a1a1a;
	background: rgba(0, 0, 0, 1);
	padding: 20px;
	.p-dialog-content {
		padding: 0;
		background: transparent;
		.box {
			padding: 0px;
			border-radius: 12px 12px 12px 12px;
			border-top: var(--sds-size-stroke-border) solid var(--Border, #1a1a1a);
			border-right: var(--sds-size-stroke-border) solid var(--Border, #1a1a1a);
			border-left: var(--sds-size-stroke-border) solid var(--Border, #1a1a1a);
			background: #131313;
			h1 {
				color: var(--Text-Color, #a4a4a4);
				font-family: Geist;
				font-size: 22px;
				font-weight: 500;
				max-width: 300px;
				span {
					color: #31c859;
				}
			}
			p {
				margin-top: 100px;
				margin-bottom: 0;
				max-width: 300px;
				color: var(--subtleText, #5f5f5f);
				font-family: Geist;
				font-size: 16px;
				font-weight: 400;
			}
			.email {
				padding: 16px;
				border-top: 1px solid #1a1a1a;
				display: flex;
				gap: 5px;
				align-items: center;
			}
		}

		button {
			margin-top: 30px;
			font-size: 1rem;
			border-radius: var(--Radius-200, 8px);
			border: 1px solid rgba(168, 168, 168, 0.27);
			background: #242424;
			color: var(--OffWhit, #ebebeb);
			font-weight: 600;
			width: 100%;
			height: 48px;
			cursor: pointer;
		}
	}
}

.trailerDialog {
	padding: 0;
	.p-dialog-content {
		background: transparent;
		padding: 0;
	}
}

@media screen and (max-width: 960px) {
	.p-menubar {
		background: transparent;
		border: none;
		.p-menubar-button {
			&:hover {
				color: #fff;
				background: transparent;
				outline: none;
			}
			&:focus {
				outline: none;
				box-shadow: none;
			}
		}
		.pi-bars {
			color: #fff;
			font-size: 20px;
		}
	}
	.p-menubar.p-menubar-mobile-active .p-menubar-root-list {
		display: flex;
		flex-direction: column;
		top: 100%;
		left: -52px;
		z-index: 1;
		width: 110px;
		border: 1px solid #1f1f1f;
		background: #1f1f1f;
		.p-menuitem-link {
			color: #fff;
			.p-menuitem-text {
				margin-left: 5px;
				color: #fff;
			}
		}
	}
}
