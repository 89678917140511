.rightPanel {
	width: 50%;
	padding-bottom: 50px;
	h1 {
		font-size: 32px;
		font-weight: 500;
		color: #ffffff;
		max-width: 450px;
		vertical-align: middle;
		margin: 0;
		display: flex;
		gap: 10px;
		align-items: center;
		img {
			height: 32px;
		}
	}
	h2 {
		font-size: 18px;
		font-weight: 500;
		color: #a7a7a8;
		margin-bottom: 20px;
	}
	.cards {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
		gap: 10px;

		.card {
			padding: 8px;
			border-radius: 12px;
			border: 1px solid var(--Border, #2a2a2a);
			background: var(--Card, #0d0d0d);
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.banner {
				min-height: 175px;
				width: 100%;
				border-radius: 7px;
				margin-bottom: 7px;
				border: 1px solid var(--Subtle-Border, #1d1d1d);
			}
			.developer {
				color: var(--Text-Color, #a4a4a4);
				font-family: Geist;
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}
			.name {
				display: flex;
				justify-content: space-between;
				color: #fff;
				font-family: Geist;
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				.beta {
					border-radius: 3px;
					border: 1px solid rgba(255, 107, 0, 0.26);
					background: rgba(119, 50, 0, 0.44);
					padding: 2px 8px;
					color: var(--Orange, #ff6b00);
					font-size: 10px;
					font-weight: 500;
					text-transform: uppercase;
				}
			}
			.mobileButton {
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 10px;
				margin-top: 8px;
				img {
					width: 100%;
					height: 10%;
				}
			}
			.downloadButton {
				margin-top: 8px;
				width: 100%;
				padding: 8px 18px;
				border-radius: 8px;
				border: 1px solid var(--Subtle-Border, #1d1d1d);
				background: var(--Subtle-Border, #1d1d1d);
				color: var(--White, #fff);
				font-size: 12px;
				font-weight: 500;
				cursor: pointer;
				transition: 300ms;
				&:hover {
					opacity: 0.8;
				}
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.rightPanel {
		padding-top: calc(env(safe-area-inset-top) + 88px);
		padding-bottom: 100px;
		width: 100%;
		min-height: 100vh;
		h1 {
			font-size: 20px;
			padding-left: 8px;
			line-height: 28px;
			img {
				height: 20px;
				margin-bottom: -2px;
			}
		}
		h2 {
			font-size: 14px;
			margin-bottom: 30px;
			line-height: 18px;
			max-width: 100%;
			padding-left: 8px;
		}
		.cards {
			.card {
				.mobileButton {
					img {
						width: 100%;
						height: 10%;
						margin-right: 0;
					}
				}
				.downloadButton {
					margin-top: 8px;
					width: 100%;
					padding: 8px 18px;
					border-radius: 8px;
					border: 1px solid var(--Subtle-Border, #1d1d1d);
					background: var(--Subtle-Border, #1d1d1d);
					color: var(--White, #fff);
					font-size: 12px;
					font-weight: 500;
					cursor: pointer;
					transition: 300ms;
					&:hover {
						opacity: 0.8;
					}
				}
			}
		}
	}
}
