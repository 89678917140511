.start {
	.desc {
		color: #909090;
		text-align: center;
		font-family: Geist;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		span {
			color: #ffffff;
		}
	}
	.form {
		width: 100%;
	}
	.inputField {
		margin-top: 30px;
		margin-bottom: 10px;
		width: 100%;
		.emailField {
			padding-left: 63px;
		}
		.tag {
			position: absolute;
			left: 5px;
			top: 10px;
			padding: 5px 10px;
			background: #10233d;
			font-family: General Sans Variable;
			font-size: 10px;
			font-weight: 600;
			line-height: 12px;
		}
		:global(.p-inputtext) {
			width: 100%;
			background: #0a0a0a;
			border: 1px solid #181818;
			color: #ffffff !important;
		}
	}
	.buttons {
		width: 100%;
		margin-top: 20px;
		position: absolute;
		bottom: 16px;
		width: 90%;
		p {
			margin: 0;
		}
		.resendButtonSection {
			background: #191919;
			padding: 10px;
			width: 100%;
			gap: 14px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 12px;
			color: #909090;
			font-size: 14px;
			font-weight: 500;
			line-height: 20px;
			border-radius: 8px;
			span {
				color: #ffffff;
			}
			img {
				width: 26px;
			}
		}
		.continueButton {
			width: 100%;
			border-radius: 8px;
			background: #cefb50;
			font-size: 1rem;
			font-weight: 600;
			color: #000000 !important;
			margin: auto;
			// margin-top: 200px;
			border: 0;
			&:disabled {
				background: #d1f177 !important;
				opacity: 1;
			}
			&:enabled {
				background: #cefb50 !important;
			}
		}
	}
}
