.main {
	margin-top: 20px;
	:global(.p-datatable) {
		font-family: "Geist" !important;
		:global(.p-datatable-header) {
			background: var(--Card);
			border-radius: 8px 8px 0 0 !important;
			border: 1px solid var(--Border) !important;
			.header {
				display: flex;
				justify-content: space-between;
				.title {
					color: var(--White) !important;
					font-family: Geist !important;
					font-size: 20px;
					font-weight: 500;
				}
				:global(.pi) {
					color: var(--White) !important;
				}
			}
		}
		:global(.p-datatable-table) {
			:global(.p-datatable-thead) {
				:global(tr) {
					border: 1px solid #1f1f1f !important;
					border-top: 0 !important;
					:global(th) {
						border: 0;
						background: var(--Card) !important;
						color: #a7a7a8 !important;
						font-size: 14px !important;
						font-weight: 500 !important;
						&:nth-last-child(1) {
							:global(.p-column-header-content) {
								justify-content: flex-end;
							}
						}
						&:nth-last-child(2) {
							:global(.p-column-header-content) {
								justify-content: center;
							}
						}
					}
				}
			}
			:global(.p-datatable-tbody) {
				border: 1px solid #1f1f1f !important;
				:global(tr) {
					background: #0d0d0d !important;
					&:nth-child(odd) {
						background: #080606 !important;
					}
					:global(td) {
						border: 0 !important;
						color: var(--White) !important;

						&:nth-last-child(1) {
							font-family: "Geist Mono" !important;
							text-align: right;
						}
						&:nth-last-child(2) {
							text-align: center;
						}
					}
				}
			}
			:global(.p-datatable-frozen-tbody) {
				:global(tr) {
					&:nth-child(odd) {
						background: linear-gradient(90deg, #14190a 0%, #101408 100%) !important;
					}
				}
			}
		}
		:global(.p-paginator) {
			border-radius: 0 0 8px 8px;
			border-top: 0px solid #1f1f1f !important;
			border-bottom: 1px solid #1f1f1f !important;
			border-left: 1px solid #1f1f1f !important;
			border-right: 1px solid #1f1f1f !important;
			background: var(--Card) !important;
			:global(.p-paginator-element) {
				border: none;
			}
			:global(.p-paginator-pages) {
				:global(.p-highlight) {
					border-radius: 8px !important;
					background: var(--OffWhit) !important;
				}
				:global(:not(.p-highlight):hover) {
					border-radius: 8px !important;
					background: transparent !important;
					color: var(--White);
				}
			}
			:global(.p-dropdown) {
				border-radius: 8px !important;
				border: 1px solid var(--Border) !important;
				background: var(--Tag, #181818) !important;
				:global(.p-dropdown-label) {
					color: var(--TextColor) !important;
				}
			}
		}
	}
}
