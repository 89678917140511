.box {
	border: 1px solid #242424;
	border-radius: 8px;
	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 12px 24px;
		.title {
			color: #fff;
			font-family: Geist;
			font-size: 20px;
			font-weight: 500;
			gap: 5px;
			display: flex;
			align-items: center;
			:global(.pi) {
				cursor: pointer;
			}
		}
		.skeleton {
			width: 150px !important;
			height: 20px !important;
		}
		.transactionHistory {
			text-decoration: none;
			border-radius: 8px;
			border: 1px solid rgba(59, 59, 59, 0.47);
			background: #191919;
			color: #d8d8d8;
			font-size: 16px;
			font-weight: 600;
			display: flex;
			padding: 10px;
			justify-content: center;
			align-items: center;
			gap: 10px;
			cursor: pointer;
			transition: 300ms;
			&:hover {
				opacity: 0.8;
			}
			img {
				height: 16px;
			}
		}
	}
	.table {
		width: 100%;
		tr {
			th {
				color: #a7a7a8;
				font-size: 14px;
				font-weight: 500;
				padding: 12px 24px;
				border-top: 1px solid #1f1f1f;
				border-bottom: 1px solid #1f1f1f;
				background: #0d0d0d;
				&.name {
					text-align: left;
				}
				&.value {
					text-align: center;
				}
				&.amount {
					text-align: right;
				}
			}

			td {
				padding: 24px;
				.skeleton {
					width: 150px !important;
					height: 20px !important;
				}
				&.name {
					div {
						display: flex;
						gap: 5px;
						align-items: center;
						color: #fff;
						font-size: 18px;
						font-weight: 500;
						span {
							color: #a7a7a8;
							font-size: 16px;
							font-weight: 500;
						}
						img {
							height: 30px;
						}
					}
				}
				&.value {
					text-align: center;
					color: #fff;
					font-size: 18px;
					font-weight: 500;
				}
				&.amount {
					text-align: right;
					color: #fff;
					font-size: 18px;
					font-weight: 500;
				}
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.box {
		.header {
			.title {
				color: #fff;
				font-size: 18px;
			}
			.transactionHistory {
				font-size: 14px;
				gap: 5px;
				padding: 8px;
				img {
					height: 14px;
				}
			}
		}
		.table {
			overflow-x: scroll;
			width: 100%;
			tr {
				th {
					font-size: 14px;
					padding: 12px 24px;
					&.name {
						text-align: left;
					}
					&.value {
						text-align: center;
					}
					&.amount {
						text-align: right;
					}
				}

				td {
					padding: 10px 5px;
					.skeleton {
						width: 60px !important;
						height: 20px !important;
					}
					&.name {
						div {
							gap: 5px;
							font-size: 14px;

							span {
								font-size: 14px;
							}
							img {
								height: 22px;
							}
						}
					}
					&.value {
						font-size: 14px;
					}
					&.amount {
						font-size: 14px;
					}
				}
			}
		}
	}
}
