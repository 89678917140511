.container {
	min-height: calc(100vh - 0px);
	display: flex;
	align-items: center;
	justify-content: center;
	background: #080808;
	background-image: url("/assets/banners/body.png");
}
.main {
	min-height: min(750px, calc(100vh - 0px));
	position: relative;
	width: clamp(300px, 100%, 450px);
	border: 1px solid #262626;
	border-radius: 24px;
	padding: 0 20px 20px 20px;
	background: #080808;
	&.autoHeight {
		height: min(700px, calc(100vh - 0px));
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
	.banner {
		width: 60%;
		display: block;
		margin: auto;
	}
	.title {
		font-size: 24px;
		font-weight: 500;
		line-height: 32px;
		width: 100%;
		margin-bottom: 20px;
		text-align: center;
		&.leftTitle {
			width: 50%;
			text-align: left;
		}
	}
}
@media only screen and (max-width: 768px) {
	.container {
		height: calc(100vh - 100px);
		display: flex;
		align-items: flex-start;
		background: #080808;
		&.flexCenter {
			align-items: center;
		}
	}
	.main {
		border: 0;
		background: transparent;
		border-radius: 0px;
		padding: 0 10px 20px 10px;
		&.autoHeight {
			height: auto;
		}
		.title {
			font-size: 24px;
			font-weight: 500;
			line-height: 32px;
			width: 60%;
			margin: auto;
			margin-bottom: 20px;
			&.leftTitle {
				margin-left: 0;
				text-align: left;
			}
		}
		.banner {
			width: 70%;
			margin-top: 0px;
		}
	}
}
