.main {
	.modalClass {
		border: 1px solid var(--Border) !important;
	}
	.maskClassName {
		opacity: var(--sds-size-stroke-border) !important;
		background: rgba(0, 0, 0, 0.75) !important;
	}
	.contentClassName {
		background: #080808 !important;
		padding: 12px !important;
		font-family: Geist !important;
		.body {
			.box {
				border-radius: 12px;
				border-top: var(--sds-size-stroke-border) solid var(--Border);
				border-right: var(--sds-size-stroke-border) solid var(--Border);
				border-left: var(--sds-size-stroke-border) solid var(--Border);
				background: #131313;
			}
			.info {
				padding: 16px;
				border-bottom: var(--sds-size-stroke-border) solid var(--Border);
				height: 200px;
				display: flex;
				justify-content: space-between;
				p {
					max-width: 80%;
					margin: 0;
					color: var(--Text-Color);
					font-size: 20px;
					font-weight: 500;
					span {
						color: var(--Orange);
					}
				}
				:global(.pi) {
					color: var(--White);
				}
			}
			.footer {
				padding: 16px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				color: var(--Text-Color);
				font-size: 16px;
				font-weight: 500;
				line-height: 24px;
				.amount {
					color: var(--OffWhit);
					span {
						color: var(--subtleText);
						margin-left: 2px;
					}
				}
			}
			.continueButton {
				width: 100% !important;
				margin-top: 10px;
				cursor: pointer !important;
				padding: 8px 0 !important;
				border-radius: 8px !important;
				border: 1px solid #1e1e1e !important;
				background: #121212 !important;
				color: var(--OffWhit) !important;
				font-family: Geist !important;
				font-size: 16px !important;
				font-weight: 600 !important;
				transition: 300ms;
				&:hover {
					opacity: var(--ButtonHoverOpacity);
				}
			}
		}
	}
}
