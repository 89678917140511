.rightPanel {
	width: 50%;
	h1 {
		font-size: 32px;
		font-weight: 500;
		line-height: 39.68px;
		text-align: left;
		color: #ffffff;
		max-width: 300px;
		vertical-align: middle;
		margin: 0;
		img {
			height: 32px;
			margin-left: 2px;
			margin-bottom: -5px;
		}
	}
	h2 {
		font-size: 18px;
		font-weight: 500;
		line-height: 22.32px;
		text-align: left;
		color: #a7a7a8;
		margin-bottom: 50px;
		max-width: 250px;
	}
	.tabBar {
		margin-bottom: 30px;
		border-radius: 16px;
		border: 1px solid #1c1c1c;
		background: #0d0d0d;
		display: flex;
		grid-gap: 10px;
		padding: 5px;
		width: fit-content;
		.tab {
			cursor: pointer;
			padding: 8px 16px;
			border-radius: 12px;
			font-size: 16px;
			font-weight: 500;
			&.active {
				background: #1c1c1c;
				color: #fff;
			}
			.count {
				margin-left: 5px;
				padding: 2px 4px;
				color: #fff;
				font-size: 16px;
				font-weight: 500;
				border-radius: 4px;
				background: #0d0d0d;
			}
		}
	}
	.box {
		border-radius: 16px;
		border: 1px solid #1a1a1a;
		background: #0d0d0d;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		height: 400px;
		img {
			height: 40px;
		}
		.nodata {
			margin-top: 10px;
			color: #a7a7a8;
			font-size: 18px;
			font-weight: 500;
		}
	}
	.NFTskeleton {
		height: 200px !important;
	}
	.NFTBox {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
		gap: 20px;
		.nft {
			border: 1px solid var(--borderColor);
			border-radius: 8px;
			img {
				width: 100%;
				border-radius: 8px;
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.rightPanel {
		padding-top: calc(env(safe-area-inset-top) + 88px);
		padding-bottom: 100px;
		width: 100%;
		min-height: 100vh;
		h1 {
			font-size: 20px;
			padding-left: 8px;
			line-height: 28px;
			img {
				height: 20px;
				margin-bottom: -2px;
			}
		}
		h2 {
			font-size: 14px;
			margin-bottom: 30px;
			line-height: 18px;
			max-width: 100%;
			padding-left: 8px;
		}
		.box {
			border-radius: 8px;
			height: 200px;
		}
	}
}
