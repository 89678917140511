.rightPanel {
	width: 50%;
	.profileBox {
		border-radius: 16px;
		border: 1px solid #1a1a1a;
		background: #0d0d0d;
		padding: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.left {
			display: flex;
			align-items: center;
			gap: 5px;
			color: #a4a4a4;
			font-size: 16px;
			font-weight: 500;
			.username {
				color: #fff;
				font-size: 28px;
				font-weight: 500;
			}
			.dp {
				background: #222;
				border-radius: 50%;
				padding: 24px;
				font-size: 25px;
			}
			img {
				height: 50px;
				width: 50px;
				border-radius: 50%;
			}
		}
		.right {
			display: flex;
			flex-direction: column;
			gap: 10px;
			img {
				height: 20px;
			}
			.levelBox {
				border-radius: 8px;
				border: 1px solid #1a1a1a;
				background: #161616;
				padding: 8px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;
				.level {
					display: flex;
					align-items: center;
					gap: 4px;
				}
				.XP {
					color: #a7a7a8;
					font-size: 18px;
					font-weight: 500;
				}
				.meter {
					flex: 1 100%;
					margin-top: 4px;
				}
			}
			.badges {
				display: flex;
				gap: 10px;
				.badge {
					border-radius: 8px;
					border: 1px solid #1a1a1a;
					background: #161616;
					padding: 8px;
					display: flex;
					align-items: center;
					color: #a7a7a8;
					font-size: 18px;
					font-weight: 500;
					span {
						margin: 0 2px 0 4px;
						color: #fff;
						font-size: 16px;
						font-weight: 600;
					}
				}
			}
		}
	}

	.activityBox {
		margin-top: 36px;
		border-radius: 16px;
		border: 1px solid #1a1a1a;
		background: #0d0d0d;
		.header {
			border-bottom: 1px solid #242424;
			padding: 20px 24px;
			font-size: 20px;
			font-weight: 500;
		}
		.activity {
			padding: 24px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 18px;
			font-weight: 500;
			span {
				display: flex;
				align-items: center;
				gap: 5px;
			}
			img {
				height: 21px;
			}
			.time {
				color: #a4a4a4;
				font-size: 16px;
				font-weight: 500;
			}
		}
	}
	.badgeTitle {
		color: #fff;
		font-size: 20px;
		font-weight: 500;
		margin-bottom: 16px;
		margin-top: 36px;
	}
	.BadgeBox {
		border-radius: 16px;
		border: 1px solid #1a1a1a;
		background: #0d0d0d;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		height: 400px;
		img {
			height: 40px;
		}
		.nodata {
			margin-top: 10px;
			color: #a7a7a8;
			font-size: 18px;
			font-weight: 500;
		}
	}
}

@media only screen and (max-width: 768px) {
	.rightPanel {
		padding-top: calc(env(safe-area-inset-top) + 88px);
		padding-bottom: 100px;
		width: 100%;
		min-height: 100vh;
		.profileBox {
			border-radius: 12px;
			flex-direction: column;
			align-items: flex-start;
			gap: 10px;
			padding: 12px;
			.left {
				font-size: 14px;
				.username {
					font-size: 22px;
				}
				img {
					height: 40px;
					width: 40px;
					border-radius: 50%;
				}
			}
			.right {
				width: 100%;
				gap: 10px;
				img {
					height: 20px;
				}
				.levelBox {
					.level {
						gap: 4px;
					}
					.XP {
						font-size: 16px;
					}
				}
				.badges {
					width: 100%;
					gap: 10px;
					.badge {
						width: 100%;
						font-size: 16px;
					}
				}
			}
		}

		.activityBox {
			margin-top: 26px;
			border-radius: 12px;
			.header {
				padding: 16px;
				font-size: 18px;
			}
			.activity {
				padding: 16px;
				font-size: 16px;
				img {
					height: 18px;
				}
				.time {
					color: #a4a4a4;
					font-size: 16px;
					font-weight: 500;
				}
			}
		}

		.badgeTitle {
			padding-left: 16px;
			font-size: 20px;
			margin-top: 26px;
		}
		.BadgeBox {
			border-radius: 16px;
			border: 1px solid #1a1a1a;
			background: #0d0d0d;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			height: 200px;
			img {
				height: 40px;
			}
			.nodata {
				margin-top: 10px;
				color: #a7a7a8;
				font-size: 18px;
				font-weight: 500;
			}
		}
	}
}
