.main {
	position: fixed;
	top: 0;
	width: 100%;
	background: #000000;
	z-index: 2;
	border-bottom: 1px solid #2d2d2d;
	padding: 5px 0;
	:global(.container) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 64px;
	}
	.logo {
		height: 26px;
		cursor: pointer;
	}
	.rightSection {
		border: 1px solid var(--borderColor);
		padding: 0px 9px;
		border-radius: 8px;
		display: flex;
		justify-content: center;
		align-items: center;

		.coin {
			color: #ffffff;
			background: #1b1c1e;
			border-radius: 8px;
			padding: 4px 15px;
			display: flex;
			align-items: center;
			margin-right: 0px;

			.bullIcon {
				margin-right: 10px;
				height: 20px;
			}

			.symbol {
				margin-left: 5px;
				color: #8e98a6;
			}
		}

		.walletAddress {
			color: #ffffff !important;
			background: transparent !important;
			border: none !important;
		}
	}
}
