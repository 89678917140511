.main {
	:global(.p-dialog-content) {
		font-family: Geist !important;
		padding: 24px !important;
		color: #ffffff !important;
		border-radius: 16px !important;
		border: 1px solid #1d1d1d !important;
		background: #0d0d0d !important;
		.header {
			background-image: url("/assets/icons/wallets/depositInfo.png");
			height: 268px;
			background-size: contain;
			background-repeat: no-repeat;
			:global(.pi) {
				background: #292d32;
				border-radius: 50%;
				height: 30px;
				width: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				right: 34px;
				top: 34px;
				cursor: pointer;
			}
		}
		.upperInfo {
			margin-top: 24px;
			.title {
				color: #fff;
				font-size: 24px;
				font-weight: 500;
			}
			.description {
				color: #a7a7a8;
				font-size: 16px;
				font-weight: 400;
				margin: 0;
				margin-top: 8px;
			}
		}
		.bottomInfo {
			margin-top: 24px;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			gap: 24px;
			.item {
				h1 {
					color: #fff;
					font-size: 18px;
					font-weight: 500;
					img {
						height: 21px;
					}
				}
				p {
					color: #a7a7a8;
					font-size: 16px;
					font-weight: 400;
				}
				.chains {
					display: flex;
					gap: 15px;
					.chain {
						display: flex;
						align-items: center;
						gap: 5px;
						img {
							height: 21px;
						}
					}
				}
				.guide {
					display: block;
					margin-top: 13px;
					color: #4285f4;
					font-size: 16px;
					font-weight: 500;
					text-decoration: none;
				}
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.main {
		&:global(.p-dialog) {
			max-height: 100% !important;
			height: 100% !important;
		}
		:global(.p-dialog-content) {
			.header {
				height: 150px;
			}
			.bottomInfo {
				flex-direction: column;
			}
		}
	}
}
