.mainDialog {
	.content {
		background: #0d0d0d !important;
		border: 1px solid #1d1d1d !important;
		padding: 20px !important;
		border-radius: 24px !important;
		text-align: center;
		color: #ffffff !important;
		font-family: Geist;
		.banner {
			height: 157px;
		}
		h1 {
			font-size: 24px;
			font-weight: 600;
		}
		p {
			margin: auto;
			max-width: 380px;
			color: #a7a7a8;
			font-size: 18px;
		}
		.buttons {
			margin-top: 20px;
			display: flex;
			gap: 20px;
			justify-content: center;
			button {
				width: 150px;
			}
		}
		.title {
			color: var(--White, #fff);
			font-family: Geist;
			font-size: 20px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			text-align: left;
			margin: 0;
			margin-top: 10px;
		}
		.para {
			color: var(--Text-Color, #a4a4a4);
			font-family: Geist;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			text-align: left;
		}
		.linkButton {
			margin-top: 50px;
			border-radius: 9.789px !important;
			border: 1px solid #3f3f3f !important;
			background: #313131 !important;
		}
		.bannerImage {
			position: relative;

			img {
				height: 100%;
				width: 100%;
			}
		}
	}
}
.loadingPannel {
	width: 100%;
}
.rightPanel {
	width: 50%;
	padding-bottom: 50px;
	h1 {
		font-size: 32px;
		font-weight: 500;
		color: #ffffff;
		max-width: 500px;
		vertical-align: middle;
		margin: 0;
		display: flex;
		gap: 10px;
		align-items: center;
		img {
			height: 32px;
		}
	}
	h2 {
		font-size: 18px;
		font-weight: 500;
		color: #a7a7a8;
		margin-bottom: 20px;
		max-width: 300px;
	}
	.box {
		background: #0d0d0d;
		border: 1px solid #1d1d1d;
		border-radius: 16px;
		margin-bottom: 20px;
		.item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 24px;
			.left {
				display: flex;
				gap: 10px;
				align-items: center;
				.icon {
					height: 59px;
				}
				.title {
					color: #fff;
					font-size: 22px;
					font-weight: 500;
					text-transform: lowercase;
					.bigSkeleton {
						width: 200px !important;
						height: 20px !important;
					}
				}
				p {
					color: #b4b4b4;
					font-size: 18px;
					font-weight: 500;
					margin: 0;
				}
			}
			.buttonSkeleton {
				width: 100px !important;
				height: 44px !important;
			}
			.disconnectButton {
				width: 150px;
				background: #e30239 !important;
				border: 0px solid #1d1d1d !important;
				color: #ffffff;
				transition: 300ms;
				&:hover {
					background: #e30239 !important;
					opacity: 0.5;
				}
			}
			.connectButton {
				width: 150px;
				background: #121212 !important;
				border: 1px solid #1d1d1d !important;
				color: #ffffff;
				transition: 300ms;
				&:hover {
					background: #3b5aff !important;
				}
			}
			.connectedButton {
				width: 180px;
				background: #000000 !important;
				border: 1px solid #1d1d1d !important;
				color: #ffffff;
				// transition: 300ms;
				opacity: 1;
				&:hover {
					:global(.pi) {
						display: none;
					}
					&::after {
						background: #e30239 !important;
						content: "Disconnect";
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
				:global(.pi) {
					font-size: 10px;
					color: #31c859;
					border: 5px solid rgba(49, 200, 89, 0.25);
					border-radius: 50%;
				}
			}
		}
	}

	.tabBar {
		padding: 8px;
		border-radius: 16px;
		border: 1px solid #1d1d1d;
		background: #0d0d0d;
		display: grid;
		grid-template-columns: 1fr 1fr;
		align-items: center;
		margin: 20px 0;

		.tab {
			display: flex;
			justify-content: center;
			align-items: center;
			color: #fff;
			font-size: 18px;
			font-weight: 500;
			gap: 5px;
			cursor: pointer;
			opacity: 0.6;
			position: relative;
			.tooltip {
				visibility: hidden;
				background-color: #555;
				color: #fff;
				text-align: center;
				border-radius: 6px;
				padding: 5px 10px;
				position: absolute;
				z-index: 1;
				bottom: 125%;
				left: 50%;
				margin-left: -60px;
				opacity: 0;
				transition: opacity 0.3s;
			}
			&:hover {
				.tooltip {
					visibility: visible;
					opacity: 1;
				}
			}
			img {
				height: 21px;
			}
			&.active {
				opacity: 1;
				pointer-events: all;
				padding: 12px 24px;
				gap: 10px;
				border-radius: 8px;
				background: #292d32;
			}
		}
	}
}
@media only screen and (max-width: 768px) {
	.rightPanel {
		padding-top: calc(env(safe-area-inset-top) + 88px);
		padding-bottom: 100px;
		width: 100%;
		min-height: 100vh;
		h1 {
			font-size: 20px;
			padding-left: 8px;
			line-height: 28px;
			img {
				height: 20px;
				margin-bottom: -2px;
			}
		}
		h2 {
			font-size: 14px;
			margin-bottom: 30px;
			line-height: 18px;
			max-width: 100%;
			padding-left: 8px;
		}
		.box {
			border-radius: 8px;
			.heading {
				font-size: 18px;
				height: 54px;
			}
			.item {
				padding: 15px 10px;
				.left {
					gap: 5px;
					.icon {
						height: 35px;
					}
					.title {
						font-size: 14px;
						.bigSkeleton {
							width: 120px !important;
							height: 20px !important;
						}
					}
					p {
						font-size: 12px;
					}
				}
				.buttonSkeleton {
					width: 80px !important;
					height: 44px !important;
				}
			}
		}
		.tabBar {
			border-radius: 8px;
			.tab {
				font-size: 16px;
				img {
					height: 17px;
				}
				&.active {
					opacity: 1;
					pointer-events: all;
					padding: 8px 24px;
					gap: 10px;
					border-radius: 8px;
					background: #292d32;
				}
			}
		}
	}
}
