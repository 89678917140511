.main {
	.modalClass {
		border: 1px solid var(--Border) !important;
	}
	.maskClassName {
		background: rgba(0, 0, 0, 0.75) !important;
	}
	.header {
		background: #000000 !important;
		color: var(--White) !important;
		font-family: Geist !important;
		font-size: 20px !important;
		font-weight: 600 !important;
		padding: 10px !important;
		border-bottom: var(--sds-size-stroke-border) solid var(--Border) !important;
	}
	.contentClassName {
		background: #080808 !important;
		padding: 20px !important;
		font-family: Geist !important;
		.body {
			padding: 24px;
			border-radius: 12px 12px 0px 0px;
			border-top: var(--sds-size-stroke-border) solid var(--Border);
			border-right: var(--sds-size-stroke-border) solid var(--Border);
			border-left: var(--sds-size-stroke-border) solid var(--Border);
			background: #131313;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			.title {
				color: #5e5e5e;
				font-size: 16px;
				font-weight: 500;
			}
			.amount {
				display: flex;
				align-items: baseline;
				gap: 5px;
				margin: 40px 0;
				color: var(--White);
				font-size: 32px;
				font-weight: 500;
				.amountField {
					width: fit-content !important;
					background: transparent !important;
					border: 0 !important;
					text-align: center !important;
					color: #fff !important;
					font-size: 26px !important;
					font-weight: 500 !important;
					:global(.p-inputtext) {
						width: fit-content !important;
						background: transparent !important;
						border: 0 !important;
						text-align: center !important;
						color: #fff !important;
						font-size: 26px !important;
						font-weight: 500 !important;
						&:focus {
							border: none !important;
							box-shadow: none !important;
						}
					}
					&:focus {
						box-shadow: none;
					}
				}
				sub {
					color: #5e5e5e;
					font-size: 16px;
					font-weight: 500;
				}
			}
			.percentages {
				display: flex;
				gap: 10px;
				justify-content: center;
				.percentage {
					color: #9b9b9b;
					cursor: pointer;
					padding: 4px 12px;
					border-radius: 17px;
					border: 0.5px solid #1b1b1b;
					opacity: var(--sds-size-stroke-border);
					background: linear-gradient(0deg, #080808 0%, #080808 100%), #080808;
				}
			}
		}
		.token {
			padding: 12px 16px;
			border-radius: 0px 0px 12px 12px;
			border-top: var(--sds-size-stroke-border) solid var(--Border);
			border-right: var(--sds-size-stroke-border) solid var(--Border);
			border-left: var(--sds-size-stroke-border) solid var(--Border);
			background: #131313;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.symbol {
				display: flex;
				align-items: center;
				gap: 2px;
				color: var(--White);
				font-size: 16px;
				font-weight: 500;
				line-height: 24px;
				letter-spacing: -0.16px;
				img {
					height: 30px;
				}
			}
			.balance {
				color: #9b9b9b;
				font-size: 14px;
				font-weight: 500;
				line-height: 16px;
				letter-spacing: -0.12px;
				span {
					color: #5e5e5e;
					font-size: 14px;
					font-weight: 500;
					line-height: 16px;
					letter-spacing: -0.12px;
				}
			}
		}
		.depositButton {
			cursor: pointer;
			border: none !important;
			margin-top: 20px;
			text-align: center !important;
			display: block !important;
			width: 100% !important;
			border-radius: 8px !important;
			background: var(--Primary-Green) !important;
			color: var(--Black) !important;
			font-size: 16px !important;
			font-weight: 600 !important;
			text-transform: uppercase !important;
			transition: 300ms;
			&:hover {
				opacity: var(--ButtonHoverOpacity);
			}
		}
	}
}

@media only screen and (max-width: 900px) {
	.main {
		.contentClassName {
			.token {
				padding: 12px 3px;
				.symbol {
					font-size: 14px;
					img {
						height: 20px;
					}
				}
				.balance {
					font-size: 14px;
				}
			}
		}
	}
}
