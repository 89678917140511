.rightPanel {
	width: 50%;
	display: flex;
	padding: 16px;
	flex-direction: column;
	align-items: center;
	gap: var(--Space-300, 12px);
	border-radius: 16px;
	background: var(--Card, #0d0d0d);
	border: 1px solid #1a1a1a;

	.box {
		border-radius: 12px;
		background: #161616;
		text-align: center;
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding: 20px 0;
		.logo {
			height: 100px;
		}

		h1 {
			text-align: center;
			font-size: 24px;
			font-weight: 400;
			max-width: 300px;
			margin: 0;
		}
		p {
			color: var(--Text-Color, #a4a4a4);
			text-align: center;
			font-size: 16px;
			font-weight: 400;
			max-width: 400px;
		}
		.tag {
			display: flex;
			align-items: center;
			gap: 3px;
			border-radius: 12px;
			border: var(--sds-size-stroke-border) solid var(--Border, #1a1a1a);
			background: var(--Card, #0d0d0d);
			color: var(--Text-Color, #a4a4a4);
			font-size: 14px;
			padding: 8px 16px;
			&.orange {
				background-color: #ff6b00;
			}
		}
	}

	.button {
		width: 100%;
		padding: 12px;
		border-radius: 8px;
		border: 1px solid rgba(168, 168, 168, 0.27);
		color: var(--OffWhit, #ebebeb);
		font-size: 16px;
		font-weight: 500;
		background-color: transparent;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 5px;
		cursor: pointer;
		transition: 300ms;
		&.joinButton {
			border-radius: var(--Radius-200, 8px);
			border: 1px solid rgba(168, 168, 168, 0.27);
			background: #242424;
		}
		&:hover {
			opacity: 0.7;
		}
		&:disabled {
			pointer-events: none;
			opacity: 0.8;
		}
	}
}

@media only screen and (max-width: 900px) {
	.rightPanel {
		margin-top: 80px;
		padding: 10px;
		width: 100%;
		padding-bottom: 100px;
	}
}
