.main {
	text-align: center;
	background: #080808;
	font-family: General Sans Variable !important;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 100%;
	width: clamp(300px, 100%, 500px);
	margin: auto;
	margin-top: 30px;
	border: 1px solid #262626;
	border-radius: 24px;
	padding: 0 20px 40px 20px;
	.banner {
		width: 70%;
		margin-top: 0px;
	}
	h2 {
		font-family: General Sans Variable;
		font-size: 24px;
		font-weight: 500;
		line-height: 32px;
		width: 60%;
		margin: auto;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	p {
		font-family: General Sans Variable;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		color: #909090;
		&.info {
			font-size: 10px;
			font-weight: 600;
			line-height: 12px;
		}
	}
	.inputField {
		margin-bottom: 10px;
		width: 100%;
		.emailField {
			padding-left: 63px;
		}
		.tag {
			position: absolute;
			left: 5px;
			top: 10px;
			padding: 5px 10px;
			background: #10233d;
			font-family: General Sans Variable;
			font-size: 10px;
			font-weight: 600;
			line-height: 12px;
		}
		:global(.p-inputtext) {
			width: 100%;
			background: #0a0a0a;
			border: 1px solid #181818;
		}
	}
	.forgotPassword {
		margin: 10px 0;
	}
	.continueButton {
		border-radius: 8px;
		background: #cefb50;
		font-size: 1rem;
		font-weight: 600;
		color: #000000 !important;
		margin: auto;
		width: 100%;
		border: 0;
		&:disabled,
		&:enabled {
			background: #cefb50 !important;
		}
	}
	.otherOptions {
		margin-top: 10px;
		color: #909090;
		font-size: 0.8rem;
		span {
			color: #ffffff;
		}
	}
}
