.main {
	:global(.p-dialog-content) {
		padding: 48px !important;
		color: #ffffff !important;
		border-radius: 16px !important;
		border: 1px solid #1d1d1d !important;
		background: #0d0d0d !important;
		font-family: Geist !important;
		.success_tick {
			height: 55px;
		}
		.header {
			display: flex;
			justify-content: space-between;
			color: #fff;
			font-size: 20px;
			font-weight: 500;
			line-height: 32px;
			margin-bottom: 5px;
			:global(.pi) {
				display: block;
				position: absolute;
				right: 20px;
				font-size: 20px;
			}
		}
		h1 {
			color: #fff;
			font-size: 24px;
			font-weight: 500;
			line-height: 32px;
		}
		.amountSection {
			margin-top: 24px;
			.label {
				color: #a7a7a8;
				font-size: 16px;
				font-weight: 500;
			}
			.amount {
				color: #fff;
				font-size: 18px;
				font-weight: 500;
			}
		}
		.fromAndToSection {
			margin-top: 24px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			gap: 31px;
			.from {
				.label {
					color: #a7a7a8;
					font-size: 16px;
					font-weight: 500;
				}
				.amount {
					margin-top: 5px;
					color: #fff;
					font-size: 16px;
					font-weight: 500;
					display: flex;
					align-items: center;
					gap: 5px;
					img {
						height: 21px;
					}
				}
			}
		}
		.track {
			display: block;
			color: #4285f4;
			font-size: 16px;
			font-weight: 500;
			margin-top: 24px;
			text-decoration: none;
		}
		.activateButton {
			margin-top: 20px;
			width: 100%;
		}
	}
}

@media only screen and (max-width: 768px) {
	.main {
		&:global(.p-dialog) {
			max-height: 100% !important;
			height: 100% !important;
		}
		:global(.p-dialog-content) {
			padding: 48px 15px !important;
		}
	}
}
