.mainDialog {
	.content {
		background: #0d0d0d !important;
		border: 1px solid #1d1d1d !important;
		padding: 20px !important;
		border-radius: 24px !important;
		text-align: center;
		color: #ffffff !important;
		font-family: Geist;
		.banner {
			height: 157px;
		}
		h1 {
			font-size: 24px;
			font-weight: 600;
		}
		p {
			margin: auto;
			max-width: 380px;
			color: #a7a7a8;
			font-size: 18px;
		}
		.buttons {
			margin-top: 20px;
			display: flex;
			gap: 20px;
			justify-content: center;
			button {
				width: 150px;
			}
		}
		.title {
			color: var(--White, #fff);
			font-family: Geist;
			font-size: 20px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			text-align: left;
			margin: 0;
			margin-top: 10px;
		}
		.para {
			color: var(--Text-Color, #a4a4a4);
			font-family: Geist;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			text-align: left;
		}
		.linkButton {
			margin-top: 50px;
			border-radius: 9.789px !important;
			border: 1px solid #3f3f3f !important;
			background: #313131 !important;
		}
		.bannerImage {
			position: relative;

			img {
				height: 100%;
				width: 100%;
			}
		}
	}
}

.rightPanel {
	width: 50%;
	h1 {
		font-size: 32px;
		font-weight: 500;
		line-height: 39.68px;
		text-align: left;
		color: #ffffff;
		max-width: 300px;
		vertical-align: middle;
		margin: 0;
		img {
			height: 32px;
			margin-left: 2px;
			margin-bottom: -5px;
		}
	}
	h2 {
		font-family: Geist;
		font-size: 18px;
		font-weight: 500;
		line-height: 22.32px;
		text-align: left;
		color: #a7a7a8;
		margin-bottom: 50px;
	}
	.box {
		background: #0d0d0d;
		border: 1px solid #1d1d1d;
		border-radius: 22px;
		margin-bottom: 20px;
		.heading {
			font-size: 20px;
			font-weight: 500;
			line-height: 24.8px;
			text-align: left;
			height: 72px;
			display: flex;
			align-items: center;
			border-bottom: 1px solid #1d1d1d;
			padding-left: 20px;
		}
		.item {
			border-bottom: 1px solid #1d1d1d;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 20px 20px;
			&:nth-last-child(1) {
				border-bottom: 0px solid #1d1d1d;
			}
			.left {
				display: flex;
				gap: 10px;
				align-items: center;
				.icon {
					height: 30px;
				}
				p {
					color: #a7a7a8;
					margin: 1px 0;
					font-weight: 500;
				}
			}
			.disconnectButton {
				width: 150px;
				background: #e30239 !important;
				border: 0px solid #1d1d1d !important;
				color: #ffffff;
				transition: 300ms;
				&:hover {
					background: #e30239 !important;
					opacity: 0.5;
				}
			}
			.connectButton {
				width: 150px;
				background: #121212 !important;
				border: 1px solid #1d1d1d !important;
				color: #ffffff;
				transition: 300ms;
				&:hover {
					background: #3b5aff !important;
				}
			}
			.connectedButton {
				width: 150px;
				background: #000000 !important;
				border: 1px solid #1d1d1d !important;
				color: #ffffff;
				// transition: 300ms;
				&:hover {
					:global(.pi) {
						display: none;
					}
					&::after {
						background: #e30239 !important;
						content: "Disconnect";
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
				:global(.pi) {
					font-size: 10px;
					color: #31c859;
					border: 5px solid rgba(49, 200, 89, 0.25);
					border-radius: 50%;
				}
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.rightPanel {
		padding-top: calc(env(safe-area-inset-top) + 88px);
		padding-bottom: 100px;
		width: 100%;
		min-height: 100vh;
		h1 {
			font-size: 20px;
			padding-left: 8px;
			line-height: 28px;
			img {
				height: 20px;
				margin-bottom: -2px;
			}
		}
		h2 {
			font-size: 14px;
			margin-bottom: 30px;
			line-height: 18px;
			max-width: 100%;
			padding-left: 8px;
		}
		.box {
			border-radius: 8px;
			.heading {
				font-size: 16px;
				height: 50px;
			}
			.item {
				padding: 15px 10px;
				.left {
					p {
						&.dotText {
							width: 36vw;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
				}
			}
			.connectButton {
				width: 132px !important;
				font-size: 14px !important;
			}
			.connectedButton {
				width: 132px !important;
				font-size: 14px !important;
				padding: 10px 5px !important;
				:global(.pi) {
					margin-right: 0 !important;
				}
			}
		}
	}
}
