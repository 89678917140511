.loader {
	height: 100vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	:global(.pi) {
		font-size: 100px;
	}
}
.start {
	.inputField {
		margin-bottom: 10px;
		width: 100%;
		position: relative;
		margin-top: 20px;
		.emailField {
			padding-left: 65px;
		}
		.tag {
			opacity: 1;
			position: absolute;
			left: 5px;
			top: -2px;
			padding: 5px 10px;
			background: #10233d;
			font-family: General Sans Variable;
			font-size: 10px;
			font-weight: 600;
			line-height: 12px;
			color: #52a8ff;
			border-radius: 8px;
		}
		:global(.p-inputtext) {
			width: 100%;
			background: #0a0a0a;
			border: 1px solid #181818;
			color: #ffffff;
		}
	}
	.errorClass {
		text-align: left;
		display: block;
		margin-bottom: 15px;
	}
	.forgotPassword {
		margin: 10px 0;
		cursor: pointer;
		transition: 250ms;
		font-weight: 500;
		color: #ffffff;
		text-decoration: none;
		&:hover {
			opacity: 0.7;
		}
	}
	.accountAlreadyLinkedMessage {
		padding: 0 10px;
		display: flex;
		align-items: center;
		gap: 10px;
		border-radius: 8px;
		background: #191919;
		color: #ea4335;
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		margin-top: 24px;
		span {
			color: #ea4335;
		}
		.infoicon {
			border-radius: 50%;
			height: 30px;
		}
	}
	.continueButton {
		border-radius: 8px;
		background: #cefb50;
		font-size: 1rem;
		font-weight: 600;
		color: #000000 !important;
		margin: auto;
		margin-top: 30px;
		width: 100%;
		border: 0;
		&:disabled {
			background: #d1f177 !important;
			opacity: 1;
		}
		&:enabled {
			background: #cefb50 !important;
			border: 0px solid transparent;
		}
	}
}

@media only screen and (max-width: 768px) {
	.form {
		width: 100%;
	}
	.continueButton {
		margin-top: 10px;
	}
}
