.main {
	:global(.p-dialog-content) {
		padding: 24px !important;
		color: #ffffff !important;
		border-radius: 16px !important;
		border: 1px solid #1d1d1d !important;
		background: #0d0d0d !important;
		.header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: #fff;
			font-family: Geist;
			font-size: 20px;
			font-weight: 500;
			line-height: 32px;
			margin-bottom: 5px;
			:global(.pi) {
				font-size: 20px;
				cursor: pointer;
			}
		}
		.subHeader {
			color: #a7a7a8;
			font-family: Geist;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
		.timer {
			margin-top: 20px;
			display: flex;
			gap: 20px;
			.item {
				text-align: center;
				width: 50%;
				border-radius: 8px;
				background: #000;
				padding: 8px 24px;
				.label {
					color: #a7a7a8;
					font-family: Geist;
					font-size: 16px;
					font-weight: 500;
				}
				.value {
					color: #fff;
					font-family: Geist;
					font-size: 16px;
					font-weight: 600;
				}
			}
		}
		.duration {
			max-width: 70%;
			margin: auto;
			margin-top: 20px;
			display: flex;
			justify-content: space-around;
			align-items: center;
			transition: 300ms;
			.item {
				transition: 300ms;
				cursor: pointer;
				padding: 4px 8px;
				border-radius: 17.872px;
				border: 0.526px solid #2a2a2a;
				background: linear-gradient(0deg, #181a1d 0%, #181a1d 100%), #080808;
				color: #fff;
				font-family: Geist;
				font-size: 16px;
				font-weight: 400;
				&.selected {
					border: 0.526px solid #1b1b1b;
					background: linear-gradient(0deg, #0070f3 0%, #0070f3 100%), #080808;
				}
			}
		}
		.permissionsIncluded {
			margin-top: 32px;
			.title {
				color: #a7a7a8;
				font-family: Geist;
				font-size: 16px;
				font-weight: 500;
				margin-bottom: 10px;
			}
			.permissions {
				display: flex;
				align-items: center;
				gap: 4px;
				.permission {
					border-radius: 14px;
					border: 1px solid #181818;
					background: rgba(95, 95, 95, 0);
					color: #a7a7a8;
					font-family: Geist;
					font-size: 14px;
					font-weight: 500;
					padding: 8px;
				}
			}
		}
		.activateButton {
			margin-top: 32px;
			width: 100%;
			background: #0070f3 !important;
		}
		.info {
			margin-top: 23px;
			border-radius: 8px;
			background: #000;
			color: #a7a7a8;
			font-family: Geist;
			font-size: 14px;
			font-weight: 500;
			display: flex;
			align-items: center;
			gap: 10px;
			padding: 10px;
			justify-content: center;
			img {
				height: 25px;
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.main {
		&:global(.p-dialog) {
			max-height: 100% !important;
			height: 100% !important;
		}
		:global(.p-dialog-content) {
			padding: 40px 24px 24px 24px !important;
			.timer {
				margin-top: 20px;
				display: flex;
				gap: 20px;
				.item {
					text-align: center;
					width: 50%;
					border-radius: 8px;
					background: #000;
					padding: 8px 15px;
					.label {
						color: #a7a7a8;
						font-family: Geist;
						font-size: 16px;
						font-weight: 500;
					}
					.value {
						color: #fff;
						font-family: Geist;
						font-size: 16px;
						font-weight: 600;
					}
				}
			}
			.duration {
				max-width: 100%;
			}
		}
	}
}
