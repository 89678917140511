.start {
	.steps {
		color: #909090;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
	}
	h2 {
		font-size: 24px;
		font-weight: 500;
		line-height: 32px;
		margin: 5px 0;
	}
	p {
		color: #909090;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
	}
	:global(.p-inputtext) {
		width: 100%;
		background: #0a0a0a;
		border: 1px solid #181818;
		color: #fff;
	}
	.usernameUrl {
		background: #191919;
		position: relative;
		width: fit-content;
		margin: 100px auto;
		padding: 10px;
		border-radius: 8px;
		display: flex;
		gap: 10px;
		align-items: center;
		.tag {
			padding: 5px 10px;
			background: #10233d;
			font-size: 10px;
			font-weight: 600;
			line-height: 12px;
			color: #52a8ff;
			border-radius: 8px;
		}
	}
	.availablity {
		margin-top: 5px;
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		color: #909090;
		display: flex;
		align-items: center;
		gap: 5px;
		span {
			height: 10px;
			width: 10px;
			border-radius: 50%;
			&.available {
				background: #36c63b;
			}
			&.not_available {
				background: red;
			}
		}
	}
	.buttons {
		position: absolute;
		bottom: 30px;
		width: 95%;
	}
	.continueButton {
		width: 100%;
		border-radius: 8px;
		background: #cefb50;
		font-size: 1rem;
		font-weight: 600;
		color: #000000 !important;
		margin: auto;
		margin-top: 200px;
		border: 0;
		&:disabled {
			background: #d1f177 !important;
			opacity: 1;
		}
		&:enabled {
			background: #cefb50 !important;
		}
	}
}
