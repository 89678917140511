.main {
	.modalClass {
		border: 1px solid var(--Border) !important;
	}
	.maskClassName {
		opacity: var(--sds-size-stroke-border) !important;
		background: rgba(0, 0, 0, 0.75) !important;
	}
	.contentClassName {
		background: #080808 !important;
		padding: 12px !important;
		font-family: Geist !important;
		.body {
			display: flex;
			gap: 12px;
			.banner {
				height: 300px;
			}
			p {
				margin: 0;
				color: var(--Text-Color);
				font-size: 24px;
				font-weight: 500;
				.amount {
					color: var(--White);
					font-family: Geist;
					font-size: 24px;
					font-weight: 500;
				}
				.type {
					color: var(--Orange);
					font-family: Geist;
					font-size: 24px;
					font-weight: 500;
				}
			}
			button {
				margin-top: 5px;
				display: block;
				width: 100%;
				cursor: pointer;
				padding: 8px 0;
				border-radius: 8px;
				border: 1px solid #1e1e1e;
				background: #121212;
				color: var(--OffWhit);
				font-family: Geist;
				font-size: 16px;
				font-weight: 600;
				transition: 300ms;
				&:hover {
					opacity: var(--ButtonHoverOpacity);
				}
			}
		}
	}
}

@media only screen and (max-width: 900px) {
	.main {
		.contentClassName {
			.body {
				display: flex;
				flex-direction: column;

				.banner {
					height: 250px;
					width: 100%;
				}
				p {
					font-size: 18px;
					.amount {
						font-size: 18px;
					}
					.type {
						font-size: 18px;
					}
				}
				button {
					margin-top: 10px;
				}
			}
		}
	}
}
